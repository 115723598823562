<script setup lang="ts">
import { ref, onBeforeMount, watch, computed } from 'vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import { type SelectOption } from '@/components/utils/CustomInput.vue'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { useUtilsStore } from '@/stores/utils'
import Api from '@/open-api'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useAssignmentStore } from '@/stores/assignment'
import type { MyAssignment } from '@/open-api/generated'
import { useRouter } from 'vue-router'
import AssignmentCard from '@/components/AssignmentCard.vue'
import WalkthroughModal from '@/components/WalkthroughModal.vue'
import { useFeaturesStore } from '@/stores/features'

// featuresStore.assignmentCompletionInsightsStatisticsFeature:
import HelpButton from '@/components/my-assignments/HelpButton.vue'
import NotificationSystem from '@/components/my-assignments/NotificationSystem.vue'
import AssignmentList from '@/components/my-assignments/AssignmentList.vue'
import RowHeaderCurrentAssignments from '@/components/my-assignments/RowHeaderCurrentAssignments.vue'
import RowHeaderPreviousAssignments from '@/components/my-assignments/RowHeaderPreviousAssignments.vue'
import Assignment from '@/components/my-assignments/StudentAssignment.vue'
import getAssignmentsWithTasks from '@/utils/getAssignmentsWithTasks'
import { generateFakeAssignments } from '@/utils/generate-fake-assignment-data'
import stateOfAssignment, { logState } from '@/utils/assignmentStatistics'
import { usePageHeadingStore } from '@/stores/pageHeading'

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('My Assignments')

definePage({
  name: 'MyAssignments',
  meta: {
    permissionLevel: 'Student'
  }
})

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const utilsStore = useUtilsStore()
const assignmentStore = useAssignmentStore()
const router = useRouter()

const featuresStore = useFeaturesStore()

const myCohortsLoading = ref(false)
const myAssignmentsLoading = ref(false)
const myAssignments = ref<MyAssignment[]>([])
const myAssignmentsContainer = ref<HTMLDivElement | undefined>(undefined)

// ==================================================
// My Assignments
// ==================================================
const myCohortOptions = ref<SelectOption[]>([])
const selectedCohorts = ref<string[]>([])

onBeforeMount(async () => {
  await loadMyCohorts()
})

async function loadMyCohorts() {
  myCohortsLoading.value = true

  try {
    const res = await Api.MyAssignments.getMyCohortsEndpoint()

    // Map to cohort options and include all options
    const cohortOptions = []
    const allCohortsValue: string[] = []

    res.cohorts.forEach((cohort) => {
      cohortOptions.push({
        name: cohort.name,
        value: [cohort.cohort_id]
      })
      allCohortsValue.push(cohort.cohort_id)
    })
    cohortOptions.unshift({
      name: 'All cohorts',
      value: allCohortsValue
    })

    myCohortOptions.value = cohortOptions
    selectedCohorts.value = allCohortsValue
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    myCohortsLoading.value = false
  }
}

const loadMyAssignments = async () => {
  if (selectedCohorts.value?.length) {
    myAssignmentsLoading.value = true
    // does not provide a search query string
    Api.MyAssignments.listMyAssignmentsEndpoint({
      cohort_ids: selectedCohorts.value
    })
      .then((res) => {
        myAssignments.value = res.assignments as MyAssignment[]
      })
      .catch((err: any) => {
        notificationStore.addNotification({
          subtitle: err?.body?.message,
          status: NotificationStatus.DANGER
        })
      })
      .finally(() => {
        myAssignmentsLoading.value = false
      })
  }
}

watch(selectedCohorts, () => {
  loadMyAssignments()
})

/* --------------------- Sticky View Assignment Panel (End) --------------------- */

const setViewDetails = (assignment: MyAssignment) => {
  assignmentStore.setCurrentAssignment(assignment)

  router.push({
    name: 'MyAssignments Details',
    params: { assignmentId: assignment.assignment_id }
  })
}

// ==================================================
// Walkthrough modal
// ==================================================

const walkthroughModalVisible = ref<boolean>(false)

const closeWalkthrough = () => {
  utilsStore.setHasViewedWalkthrough(true)
  walkthroughModalVisible.value = false
}

onBeforeMount(() => {
  if (!utilsStore.hasViewedWalkthrough) {
    walkthroughModalVisible.value = true
  }
})

// featuresStore.assignmentCompletionInsightsStatisticsFeature:
const assignmentsLoading = ref(false)
const assignmentsWithTasks = ref<MyAssignment[]>([])
const fakeAssignments = ref<MyAssignment[]>([])

const isUsingFakeData = ref<boolean>(false)
const assignments = computed<MyAssignment[]>(() =>
  isUsingFakeData.value ? fakeAssignments.value : assignmentsWithTasks.value
)
const isOpen = (assignment: MyAssignment): boolean => {
  const state = stateOfAssignment(assignment)
  if (state.isGradeNotFinal) {
    if (state.isMandatory && state.isLongOverdue) {
      // chuck out long overdue mandatory assignments
      return false
    } else if (state.isOptional && state.isOverdue) {
      // chuck out optional assignments overdue by any amount
      return false
    }
    // anything without a final grade stays
    return true
  } else {
    // anything with a final grade gets chucked
    return false
  }
}
const isPast = (assignment: MyAssignment): boolean => !isOpen(assignment)

const fromFutureToPast = (a: MyAssignment, b: MyAssignment): number =>
  a.due_date > b.due_date ? -1 : a.due_date < b.due_date ? 1 : 0
const fromPastToFuture = (a: MyAssignment, b: MyAssignment): number =>
  a.due_date < b.due_date ? -1 : a.due_date > b.due_date ? 1 : 0

const openAssignments = computed<MyAssignment[]>(() =>
  assignments.value.filter(isOpen).sort(fromPastToFuture)
)

const pastAssignments = computed<MyAssignment[]>(() =>
  assignments.value.filter(isPast).sort(fromFutureToPast)
)

const loadMyAssignmentsProperly = async () => {
  fakeAssignments.value = generateFakeAssignments(50)
  try {
    assignmentsLoading.value = true
    assignmentsWithTasks.value = await getAssignmentsWithTasks()
  } catch (err: any) {
    notificationStore.addDANGER(err?.body?.message)
  } finally {
    assignmentsLoading.value = false
  }
}

// No point in putting a MyAssignmentSummary into the store,
// the details page needs a full MyAssignment so it will fetch anyway
const setViewDetailsProperly = (assignmentId: string) => {
  if (isUsingFakeData.value) {
    const assignment = assignments.value.find((a) => a.assignment_id === assignmentId)!
    logState(assignment)
    notificationStore.addINFO('Logged to console')
    // don't send to assignment details page, as it's a fake assignment
  } else {
    router.push({
      name: 'MyAssignments Details',
      params: { assignmentId }
    })
  }
}
// If we do want to cache API results in the store to improve loads,
// we can do it in a much better way, later.
// But we would probably do SSR instead.

watch(
  [featuresStore],
  () => {
    if (featuresStore.assignmentCompletionInsightsStatisticsFeature) {
      loadMyAssignmentsProperly()
    }
  },
  { immediate: true }
)
</script>

<template>
  <!-- <template v-if="featuresStore.assignmentCompletionInsightsStatisticsFeature"> -->
  <template v-if="false">
    <div class="absolute left-2/3 top-0 z-50 flex flex-row gap-x-5">
      <CustomButton
        :button-type="isUsingFakeData ? 'admin-secondary' : 'admin-primary'"
        @click="isUsingFakeData = !isUsingFakeData"
      >
        {{ isUsingFakeData ? 'use real data' : 'use fake data' }}
      </CustomButton>
    </div>
    <div class="flex flex-col">
      <div class="flex w-full flex-row flex-nowrap items-center justify-between">
        <HelpButton @view="walkthroughModalVisible = true" />
        <h1 class="mb-6 mt-2 text-4xl">My Assignments</h1>
        <NotificationSystem @open="() => console.log('open')" />
      </div>
      <AssignmentList>
        <RowHeaderCurrentAssignments :assignments-loading="assignmentsLoading" />
        <Assignment
          v-for="assignment in openAssignments"
          :key="assignment.assignment_id"
          :assignment="assignment"
          @view="setViewDetailsProperly"
        />
        <RowHeaderPreviousAssignments
          v-if="pastAssignments.length"
          :assignments-loading="assignmentsLoading"
        />
        <Assignment
          v-for="assignment in pastAssignments"
          :key="assignment.assignment_id"
          :assignment="assignment"
          @view="setViewDetailsProperly"
        />
      </AssignmentList>
    </div>
    <WalkthroughModal :visible="walkthroughModalVisible" :on-close="closeWalkthrough" />
  </template>
  <template v-else>
    <div class="mb-8 hidden flex-none items-center justify-end bg-white pb-2 md:flex">
      <div class="flex items-center gap-5">
        <CustomButton button-type="admin-secondary" @click="walkthroughModalVisible = true">
          Watch tutorial
        </CustomButton>
      </div>
    </div>
    <!---------------------- <Mobile> Header ---------------------->
    <div class="mb-5 flex flex-none items-center justify-between px-1 md:hidden">
      <h1 class="text-lg">My Assignments</h1>
      <div class="flex items-center gap-5">
        <CustomButton
          button-size="md"
          button-type="admin-secondary"
          @click="walkthroughModalVisible = true"
        >
          Watch tutorial
        </CustomButton>
      </div>
    </div>

    <div v-if="myAssignmentsLoading || myCohortsLoading" class="w-full flex-1 py-20">
      <AppLoadingSpinner class="w-full" :loading="true" />
    </div>
    <div v-else class="flex flex-1 flex-col">
      <div
        v-if="!myAssignments?.length"
        class="flex flex-1 items-center justify-center text-sc-grey-600"
      >
        You have no assignments to display
      </div>
      <div v-else ref="myAssignmentsContainer" class="flex flex-1 overflow-hidden">
        <div id="assignment-list" class="flex w-full flex-1 grow flex-col gap-3">
          <div
            v-for="(assignment, assignmentIndex) in myAssignments"
            :key="`assignment_${assignmentIndex}`"
          >
            <AssignmentCard :assignment="assignment" @view="setViewDetails" />
          </div>
        </div>
      </div>
    </div>

    <WalkthroughModal :visible="walkthroughModalVisible" :on-close="closeWalkthrough" />
  </template>
</template>
