<script setup lang="ts">
import type { RubricSummary } from '@/open-api/generated'
import { ref, onBeforeMount, reactive, watch, nextTick } from 'vue'
import CustomList, { type ListData } from '@/components/CustomList.vue'
import Api from '@/open-api'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import { useAuthStore } from '@/stores/auth'
import { useUtilsStore } from '@/stores/utils'
import { useRouter, useRoute } from 'vue-router'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'
import { usePageHeadingStore } from '@/stores/pageHeading'

definePage({
  name: 'Rubrics',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const router = useRouter()
const route = useRoute('Rubrics')
const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Rubrics')
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const utilsStore = useUtilsStore()

// Watch for organization change
watch(
  () => utilsStore.organizationId,
  () => {
    rubric.pagination.page = 1
    setRubricsList(search.value)
  }
)

// ==================================================
// Rubrics
// ==================================================
const rubric = reactive({
  loading: false,
  list: [] as RubricSummary[],
  pagination: { items_per_page: 15, page: 1, total: 0 },
  tableHeaders: [
    {
      name: 'Internal Label',
      value: 'internal_label'
    },
    {
      name: 'Description',
      value: 'internal_description'
    },
    {
      name: 'Actions',
      value: 'action-view'
    }
  ]
})

onBeforeMount(async () => {
  let querySearch = EMPTY_STRING_SEARCH_ALL

  if (route.query.search) {
    search.value = route.query.search as string
    querySearch = route.query.search as string
  }

  setRubricsList(querySearch)
})

const search = ref(EMPTY_STRING_SEARCH_ALL)

const setRubricsList = async (listSearch = EMPTY_STRING_SEARCH_ALL) => {
  rubric.loading = true

  if (listSearch !== search.value) {
    rubric.pagination.page = 1
  }
  if (listSearch === '') {
    listSearch = EMPTY_STRING_SEARCH_ALL
  }
  search.value = listSearch

  try {
    const rubrics = await Api.Content.listRubricsEndpoint(
      listSearch,
      rubric.pagination.items_per_page,
      rubric.pagination.page,
      authStore.isSuperAdminUser ? utilsStore.organizationId : undefined
    )

    router.push({
      query: {
        ...route.query,
        search: search.value
      }
    })

    rubric.pagination.total = rubrics.pagination.total_pages
    rubric.list = rubrics.rubrics
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    nextTick(() => {
      rubric.loading = false
    })
  }
}

const createRubric = async () => {
  router.push({
    name: 'Rubric View',
    params: { rubricId: 'new' },
    query: { mode: 'create' }
  })
}
const listView = ref('table')

const changePage = (page: number) => {
  rubric.pagination.page = page
  setRubricsList(search.value)
}

const viewRouteDetails = (rubric_id: string) => {
  return {
    name: 'Rubric View',
    params: { rubricId: rubric_id }
  }
}

const onView = (data: { id: string; index: number }) => {
  router.push({
    name: 'Rubric View',
    params: { rubricId: data.id }
  })
}
</script>

<template>
  <CustomList
    create-button="Create Rubric"
    :pagination="rubric.pagination"
    :has-list-options="false"
    :view-type="listView"
    :list-headers="rubric.tableHeaders"
    :list-data="rubric.list"
    :has-header="true"
    :generate-view-link="(data: ListData) => viewRouteDetails(data.rubric_id as string)"
    :search="search"
    :loading="rubric.loading"
    @on-view="onView"
    @on-search="(search: string) => setRubricsList(search)"
    @on-create="createRubric"
    @on-change-page="(page: number) => changePage(page)"
  >
  </CustomList>
</template>
