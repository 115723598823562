<script setup lang="ts">
import {
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  HandThumbUpIcon
} from '@heroicons/vue/24/outline'
import CustomModal from '@/components/utils/CustomModal.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'

import { useRoute } from 'vue-router'
import { ref, reactive, computed, onBeforeMount } from 'vue'
import Api from '@/open-api'
import { EntryTypeApiEnum, type TranscriptEntryApi } from '@/open-api/generated'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import { useAuthStore } from '@/stores/auth'
import { useUtilsStore } from '@/stores/utils'
import { useCurrentConversation } from '@/composables/useCurrentConversation'
import { generateTranscriptPdf } from '@/utils/pdf'

definePage({
  name: 'Cohort Assignment Task Attempt Transcript',
  meta: {
    permissionLevel: 'Educator'
  }
})

const route = useRoute<'Cohort Assignment Task Attempt Transcript'>()
const { conversationId } = route.params
const authStore = useAuthStore()
const notificationStore = useNotificationStore()
const utilsStore = useUtilsStore()

const { currentConversation, currentConversationIndex } = useCurrentConversation()

const transcripts = ref<(TranscriptEntryApi & { editedMessage?: string })[][]>([])
const transcriptLoading = ref(false)

onBeforeMount(() => {
  transcriptLoading.value = true
  const axiosRequests = [Api.Conversation.getTranscriptEndpoint(conversationId)]

  Promise.all(axiosRequests)
    .then(async (res) => {
      transcripts.value = res.map((response) => response.entries)

      if (authStore.isSuperAdminUser && currentConversation.value?.ended_at) {
        await getEditedTranscripts()
      }
    })
    .catch((err: any) => notificationStore.addDANGER(err?.body?.message))
    .finally(() => {
      transcriptLoading.value = false
    })
})

const usedActions = computed(() => {
  return transcripts.value?.[currentConversationIndex.value]?.filter(
    (entry) => entry.entry_type === EntryTypeApiEnum.PERFORMED_ACTION
  )
})

const usedInvestigations = computed(() => {
  return transcripts.value?.[currentConversationIndex.value]?.filter(
    (entry) => entry.entry_type === EntryTypeApiEnum.PERFORMED_INVESTIGATION
  )
})

const generatePdf = async () => {
  const transcript = transcripts.value?.[currentConversationIndex.value]
  const characterName =
    currentConversation.value?.given_name + ' ' + currentConversation.value?.family_name
  if (transcript) {
    try {
      await generateTranscriptPdf(transcript, characterName)
    } catch (error) {
      notificationStore.addDANGER('Failed to generate the requested PDF.')
    }
  }
}

// ==================================================
// Investigation Modal
// ==================================================
const selectedInvestigationIndex = ref(0)
const modalStatus = ref(false)

const openModal = (index: number) => {
  selectedInvestigationIndex.value = index
  modalStatus.value = true
}

const closeModal = () => {
  modalStatus.value = false
  setTimeout(() => {
    selectedInvestigationIndex.value = 0
  }, 300)
}

// ==================================================
// Data Science
// ==================================================

const dataScience = reactive({
  modalStatus: false,
  correctedResponse: '',
  loading: false
})

const getEditedTranscripts = () => {
  dataScience.loading = true
  Api.DataScienceApi.getMessageCorrectionsEndpoint(
    transcripts.value[currentConversationIndex.value][0]?.conversation_id
  )
    .then((res) => {
      res.message_corrections.forEach((editedTranscript) => {
        const foundTranscriptMessageIndex = transcripts.value[
          currentConversationIndex.value
        ].findIndex(
          (transcript) => transcript.transcript_entry_id === editedTranscript.transcript_entry_id
        )

        transcripts.value[currentConversationIndex.value][
          foundTranscriptMessageIndex
        ].editedMessage = editedTranscript.corrected_text
      })
    })
    .catch((err: any) => notificationStore.addDANGER(err?.body?.message))
    .finally(() => {
      dataScience.loading = false
    })
}

const selectedTranscriptEntry = ref<(TranscriptEntryApi & { editedMessage?: string }) | null>(null)

const openDataModal = (transcriptEntry: TranscriptEntryApi) => {
  dataScience.modalStatus = true
  selectedTranscriptEntry.value = transcriptEntry
  dataScience.correctedResponse = selectedTranscriptEntry.value?.editedMessage || ''
}

const submitNewResponse = (currentResponse = false) => {
  dataScience.loading = true
  Api.DataScienceApi.correctCharacterAnswerMessageEndpoint({
    transcript_entry_id: selectedTranscriptEntry.value?.transcript_entry_id || '',
    corrected_text: selectedTranscriptEntry.value?.character_text || dataScience.correctedResponse
  })
    .then(() => {
      notificationStore.addNotification({
        subtitle: currentResponse
          ? 'Response approved successfully'
          : 'Response updated successfully',
        status: NotificationStatus.SUCCESS
      })

      const foundTranscriptMessageIndex = transcripts.value[
        currentConversationIndex.value
      ].findIndex(
        (transcript) =>
          transcript.transcript_entry_id === selectedTranscriptEntry.value?.transcript_entry_id
      )

      transcripts.value[currentConversationIndex.value][foundTranscriptMessageIndex].editedMessage =
        currentResponse
          ? (selectedTranscriptEntry.value?.character_text as string)
          : dataScience.correctedResponse

      selectedTranscriptEntry.value = null
      dataScience.modalStatus = false
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      dataScience.loading = false
    })
}

const approveModelResponse = (transcriptEntry: TranscriptEntryApi) => {
  selectedTranscriptEntry.value = transcriptEntry
  submitNewResponse(true)
}

const closeDataModal = () => {
  dataScience.modalStatus = false
  selectedTranscriptEntry.value = null
}
</script>

<template>
  <div
    :class="['my-3 flex h-full flex-col gap-5 overflow-hidden px-3 md:my-5 md:flex-row md:px-5']"
  >
    <div :class="['flex grow flex-col overflow-hidden md:w-[60%] md:grow-0']">
      <div class="flex h-full flex-col items-center gap-5 overflow-hidden">
        <div class="flex w-full flex-row justify-between">
          <h2 class="self-start text-lg md:flex">Transcript</h2>
          <CustomButton
            button-size="md"
            :end-icon="ArrowDownTrayIcon"
            button-type="admin-secondary"
            @click="generatePdf"
          >
            Download PDF
          </CustomButton>
        </div>
        <div
          id="pdf-element"
          class="flex min-w-full flex-col gap-3 overflow-y-auto rounded-mlg border border-sc-grey-300 bg-white p-4 pt-0"
        >
          <div class="sticky -top-3 z-10 flex flex-col items-center justify-center bg-white pt-3">
            <div class="mt-4 flex h-[70px] w-[70px] self-center rounded-full">
              <img
                :src="currentConversation?.avatar_url"
                :alt="`Character avatar for ${currentConversation?.given_name} ${currentConversation?.family_name}`"
                class="center-cropped h-full w-full rounded-full object-cover"
              />
            </div>

            <div class="flex self-center">
              <p>
                {{ `${currentConversation?.given_name} ${currentConversation?.family_name}` }}
              </p>
            </div>
          </div>

          <template v-if="!transcriptLoading || true">
            <div
              v-for="(message, index) in transcripts[currentConversationIndex]"
              :key="index"
              :class="[
                'flex max-w-[75%] items-center gap-3',
                { 'self-end': !message.character_text }
              ]"
            >
              <div v-if="message?.character_text" class="h-[30px] w-[30px] rounded-full">
                <img
                  class="center-cropped h-full w-full min-w-[30px] rounded-full object-cover"
                  :src="currentConversation?.avatar_url"
                  :alt="`Character avatar for ${currentConversation?.given_name} ${currentConversation?.family_name}`"
                />
              </div>

              <div
                :class="[
                  'relative flex rounded-[20px] p-3',
                  !message.character_text
                    ? 'self-end rounded-tr-none bg-sc-orange-300'
                    : 'rounded-tl-none bg-sc-grey-100'
                ]"
              >
                <div
                  v-if="message?.editedMessage"
                  class="absolute right-0 top-[-5px] rounded-full bg-sc-grey-800 px-1 text-[10px] text-white"
                >
                  Corrected
                </div>

                <div v-if="message?.student_text || message?.character_text">
                  {{ message?.student_text || message?.character_text }}
                </div>
                <div v-else-if="message?.action_text">
                  <span class="font-medium">Action Taken: </span>{{ message.action_text }}
                </div>
                <div v-else-if="message?.investigation_text">
                  <span class="font-medium">Investigated : </span>{{ message?.investigation_text }}
                </div>
              </div>
              <template
                v-if="
                  message.character_text &&
                  authStore.isSuperAdminUser &&
                  (currentConversation?.ended_at || route.params.conversationId !== 'vr-scene')
                "
              >
                <div
                  class="flex h-6 w-6 min-w-[1.5rem] cursor-pointer items-center justify-center rounded-full bg-sc-grey-100 hover:bg-sc-grey-200"
                  @click="openDataModal(message)"
                >
                  <EllipsisHorizontalIcon class="h-4 w-4" />
                </div>
                <div
                  v-if="!message?.editedMessage"
                  class="flex h-6 w-6 min-w-[1.5rem] cursor-pointer items-center justify-center rounded-full bg-sc-grey-100 hover:bg-sc-grey-200"
                  @click="approveModelResponse(message)"
                >
                  <HandThumbUpIcon class="h-3.5 w-3.5" />
                </div>
              </template>
            </div>
          </template>

          <AppLoadingSpinner v-else loading />
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-3 md:w-[40%] md:overflow-hidden">
      <div :class="['hidden h-full flex-col gap-3 md:flex md:overflow-hidden']">
        <div
          class="flex flex-col border-b border-sc-grey-300 pb-1.5 md:grow md:overflow-hidden md:border-b-0"
        >
          <div class="flex cursor-pointer items-center justify-between">
            <h3 class="-mt-[15px] flex h-[50px] min-h-[50px] items-center text-lg md:mt-0">
              Investigations Used
              <span class="pl-1 text-base text-sc-grey-600"
                >({{ usedInvestigations?.length || 0 }})</span
              >
            </h3>
          </div>

          <div
            v-if="!utilsStore.isMobile"
            class="mb-3 flex w-full origin-top flex-col gap-3 transition-transform md:scale-100 md:overflow-hidden"
          >
            <template v-if="usedInvestigations?.length">
              <div class="flex flex-col gap-3 md:overflow-y-auto">
                <div
                  v-for="(investigation, investigationIndex) in usedInvestigations"
                  :key="`${investigationIndex}-investigation`"
                  :class="[
                    'flex h-[50px] min-h-[50px] w-full cursor-pointer items-center justify-center rounded-md border hover:bg-sc-grey-50'
                  ]"
                  @click="
                    () => {
                      openModal(investigationIndex)
                    }
                  "
                >
                  <p class="text-lg">
                    {{ investigation?.investigation_text }}
                  </p>
                </div>
              </div>
            </template>

            <p v-else class="mt-3 w-full text-center text-sc-grey-600">
              You did not view any investigations during this conversation
            </p>
          </div>
        </div>

        <div class="flex flex-col md:grow md:overflow-hidden">
          <div class="flex cursor-pointer items-center justify-between">
            <h3 class="flex h-[50px] min-h-[50px] items-center text-lg">
              Actions Used
              <span class="pl-1 text-base text-sc-grey-600">({{ usedActions?.length || 0 }})</span>
            </h3>
          </div>
          <div
            v-if="!utilsStore.isMobile"
            class="flex w-full origin-top flex-col gap-3 transition-transform md:overflow-hidden"
          >
            <template v-if="usedActions?.length">
              <div class="flex flex-col gap-3 md:overflow-y-auto">
                <div
                  v-for="(action, actionIndex) in usedActions"
                  :key="`${actionIndex}-action`"
                  :class="[
                    'flex h-[50px] min-h-[50px] w-full items-center justify-center rounded-md border'
                  ]"
                >
                  <p class="text-lg">
                    {{ action?.action_text }}
                  </p>
                </div>
              </div>
            </template>
            <p v-else class="mt-3 w-full text-center text-sc-grey-600">
              You did not use any actions during this conversation
            </p>
          </div>
        </div>
      </div>
    </div>
    <CustomModal
      v-if="currentConversation?.investigations?.[selectedInvestigationIndex]"
      v-model="modalStatus"
      @on-close="closeModal"
    >
      <div class="flex h-screen w-full flex-col gap-3 overflow-hidden">
        <h3 class="basis-auto text-xl">
          {{ currentConversation?.investigations?.[selectedInvestigationIndex].public_label }}
        </h3>
        <img
          :src="currentConversation?.investigations?.[selectedInvestigationIndex].file_url"
          :alt="currentConversation?.investigations?.[selectedInvestigationIndex].public_label"
          class="h-full w-full grow overflow-hidden object-contain"
        />
        <CustomButton class="basis-auto self-end" @click="modalStatus = false"> Done </CustomButton>
      </div>
    </CustomModal>

    <!-- data science modal -->
    <CustomModal v-model="dataScience.modalStatus" @on-close="closeDataModal">
      <div class="flex w-full flex-col gap-5 overflow-hidden">
        <h3 class="basis-auto text-xl">Correct character response</h3>
        <div>
          <CustomInput
            :model-value="selectedTranscriptEntry?.character_text || ''"
            label="Original Response"
            input-type="textarea"
            :read-only="true"
          />
          <CustomInput
            v-if="!dataScience.loading"
            v-model="dataScience.correctedResponse"
            input-type="textarea"
            :loading="dataScience.loading"
            label="Corrected Response"
          />

          <AppLoadingSpinner v-else loading />
        </div>
        <div class="flex gap-5">
          <CustomButton
            button-type="admin-secondary"
            class="basis-auto self-end"
            @click="closeDataModal"
          >
            Cancel
          </CustomButton>
          <CustomButton
            class="basis-auto self-end"
            :disabled="dataScience.loading"
            @click="() => submitNewResponse()"
          >
            Submit new response
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  </div>
</template>
