<script setup lang="ts">
import { ref, watch, type Component } from 'vue'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/modern/ui/collapsible'
import { Button } from '@/components/modern/ui/button'
import NavItem from './NavItem.vue'
import { TriangleRightIcon, TriangleDownIcon } from '@radix-icons/vue'
import type { RouteLocationRaw } from 'vue-router'

const props = defineProps<{
  label: string
  defaultOpen: boolean
  deprecated?: boolean
  routes: {
    label: string
    to: RouteLocationRaw
    icon: Component
  }[]
}>()

const localStorageKey = `collapsible-nav:${props.label}`
const localStorageIsOpen: string | null = localStorage.getItem(localStorageKey)

const internalIsOpen = ref<boolean>(
  localStorageIsOpen ? (JSON.parse(localStorageIsOpen) as boolean) : props.defaultOpen
)

watch(internalIsOpen, () => {
  localStorage.setItem(localStorageKey, JSON.stringify(internalIsOpen.value))
})
</script>

<template>
  <Collapsible v-model:open="internalIsOpen" as="nav" class="w-full">
    <Button as-child variant="ghost" size="xs" class="hover:bg-white">
      <CollapsibleTrigger class="flex w-full items-center justify-between">
        <h4 class="text-sm font-semibold">
          {{ props.label }}
          <span v-if="props.deprecated" class="text-[10px] uppercase">Legacy</span>
        </h4>
        <TriangleRightIcon v-if="!internalIsOpen" />
        <TriangleDownIcon v-else />
        <span class="sr-only">Toggle Nav</span>
      </CollapsibleTrigger>
    </Button>
    <CollapsibleContent class="space-y-0.5 pt-0.5">
      <NavItem v-for="route in props.routes" :key="route.label" :route="route" />
    </CollapsibleContent>
  </Collapsible>
</template>
