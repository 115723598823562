<script setup lang="ts">
import { watch, reactive, onBeforeMount, ref, computed } from 'vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import InputFile from '@/components/input/InputFile.vue'
import CustomModal from '@/components/utils/CustomModal.vue'
import { useContentStore } from '@/stores/content'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { SpeakerWaveIcon, UserIcon } from '@heroicons/vue/24/outline'
import { useAuthStore } from '@/stores/auth'
import useUploadImageEndpoint from '@/composables/api/useUploadImageEndpoint'
import type { CharacterOutput, UpdateCharacterInput } from '@/open-api/generated'
import Api from '@/open-api'
import { nextTick } from 'vue'
import { deepCopy } from '@/lib/utils'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import { SUPPORTED_IMAGE_MIMETYPES } from '@/constants'

definePage({
  name: 'Character Config',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const contentStore = useContentStore()
const route = useRoute('Character Config')
const authStore = useAuthStore()
const isReadOnly = computed(() => {
  return (
    route.params.characterId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingCharacter as CharacterOutput)?.owning_organization_id
    )
  )
})

// ==================================================
// Image Modal
// ==================================================
const {
  src: modalImage,
  mime: modalMime,
  loading: modalFileLoading,
  uploadFile,
  deleteFile
} = useUploadImageEndpoint()

const imgModalIsOpen = ref(false)

watch(imgModalIsOpen, (newVal) => {
  if (newVal) {
    modalImage.value = currentEditingCharacter.avatar_url || ''
  }
})
onBeforeRouteLeave((to, from, next) => {
  if (route.params.characterId !== 'new' && !canSave.value) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

const editAvatar = () => {
  if (!isReadOnly.value) {
    imgModalIsOpen.value = true
  }
}

const setImage = () => {
  currentEditingCharacter.avatar_url = modalImage.value
  imgModalIsOpen.value = false
}

// ==================================================
// Character form
// ==================================================
const saveLoading = ref(false)
const currentEditingCharacter = reactive<Partial<UpdateCharacterInput>>({})

onBeforeMount(() => {
  nextTick(() => {
    Object.assign(currentEditingCharacter, contentStore.editingCharacter)
  })
})

watch(
  currentEditingCharacter,
  (newVal) => {
    if (route.params.characterId === 'new') {
      contentStore.setEditingCharacter(newVal)
    }
  },
  { deep: true }
)

const saveCharacterConfig = () => {
  const { editingCharacter } = contentStore

  if (!editingCharacter) {
    return
  }

  saveLoading.value = true

  const updateCharacter = deepCopy(currentEditingCharacter) as
    | UpdateCharacterInput
    | CharacterOutput

  const update = { ...updateCharacter } as UpdateCharacterInput

  update.allowlist = authStore.isAtLeastStaffUser
    ? updateCharacter.allowlist?.map((listItem) => {
        if (typeof listItem === 'string') {
          return listItem
        }
        return listItem?.organization_id
      })
    : null

  Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
    .then((res) => {
      saveLoading.value = false
      Object.assign(currentEditingCharacter, res)
      contentStore.setEditingCharacter(res)
      notificationStore.addNotification({
        subtitle: 'Character Config successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      saveLoading.value = false

      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
}

const canSave = computed(() => {
  const {
    given_name,
    family_name,
    avatar_url,
    clinician_role,
    public_description,
    internal_label,
    voice
  } = currentEditingCharacter

  const storeEditingJSON = JSON.stringify(contentStore.editingCharacter)
  const componentEditingJSON = JSON.stringify(currentEditingCharacter)

  return (
    !given_name?.trim() ||
    !clinician_role ||
    !avatar_url ||
    !family_name?.trim() ||
    !internal_label?.trim() ||
    !voice ||
    !public_description?.trim() ||
    storeEditingJSON === componentEditingJSON
  )
})

const voiceSearch = ref('')

const filteredVoiceOptions = computed(() => {
  if (!voiceSearch.value) {
    return voiceOptions
  }
  return voiceOptions.filter((option) => option.name.includes(voiceSearch.value))
})

const voiceOptions = [
  {
    name: 'USA F #1',
    value: 'Joanna',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_joanna_neural.35791e787f6fea83dc043503641b5845986680d4.mp3'
  },
  {
    name: 'USA F #2',
    value: 'Kendra',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_kendra_neural.0ff6f00b027cef444b5cd0222284b85c62527210.mp3'
  },
  {
    name: 'USA F #3',
    value: 'Kimberly',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_kimberly_neural.245695794e270aaeeee6ce022b5f694c88d0be45.mp3'
  },
  {
    name: 'USA F #4',
    value: 'Salli',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_salli_neural.c8e257382af31ffce5e974b34e2d41b9605f17e4.mp3'
  },
  {
    name: 'USA F #5',
    value: 'Ruth',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/Ruth_sample.ac0a5ebb0238fcda47d5a0380a592ea42fea0de1.mp3'
  },
  {
    name: 'USA M #1',
    value: 'Matthew',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_matthew_neural.ebba918740a628327e0826c99e57d60f424c5bb8.mp3'
  },
  {
    name: 'USA M #2',
    value: 'Joey',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_joey_neural.6dd2fab4d1c38a55b77771b5a613cfd0e60465c1.mp3'
  },
  {
    name: 'USA M #3',
    value: 'Stephen',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/Stephen_sample.9dcc1b127f225f7c15e6b3da800b362101a95403.mp3'
  },
  {
    name: 'USA F Paeds #1',
    value: 'Ivy',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_ivy_neural.87e42a0bf305f7d1624262c8c36f796d2c769469.mp3'
  },
  {
    name: 'USA M Paeds #1',
    value: 'Justin',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_justin_neural.2829d189944815a0b0db02f4d36e8bf7043c9445.mp3'
  },
  { name: 'USA M Paeds #2', value: 'Kevin' },
  {
    name: 'AUS F #1',
    value: 'Olivia',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/Olivia_AudoSample.96685a4849af399e6b09583636d41bcb37790552.wav'
  },
  {
    name: 'AUS M #1',
    value: 'Russell',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/russell.85286a07b33c9ee2bd97dd994294ccebb50a784d.mp3'
  },
  {
    name: 'GBR F #1',
    value: 'Amy',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/Amy_Sample.b8f1d0e9168d2a34eba5dc3ec8183a258d6720f5.wav'
  },
  {
    name: 'GBR F #2',
    value: 'Emma',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_emma_neural.be854134ed0f48f776e209d2def02b38d5e6afb7.mp3'
  },
  {
    name: 'GBR M #1',
    value: 'Brian',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/features_brian_neural.369e3890f6aa99becf5415d535eb092195043697.mp3'
  },
  {
    name: 'GBR M #2',
    value: 'Arthur',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/arthur_neural.0702ff46a3a3c414310aa9b0b1b9fbd1ebf83886.mp3'
  },
  {
    name: 'NZL F #1',
    value: 'Aria',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/aria_neural.3eb12a43c21a37e61a53a78a9975650c07337461.wav'
  },
  {
    name: 'IND F #3',
    value: 'Kajal',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/kajal_sample_english.6e07ddc2fc220e93d7f295f5cb95f5b97255830a.mp3'
  },
  {
    name: 'IRL F #1',
    value: 'Niamh',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/niamh.3ae414d17d79235805ca46ec631f3fcebe71a0bb.mp3'
  },
  {
    name: 'ZAF F #1 (LQ)',
    value: 'Ayanda',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/Ayanda_new%20sample_website.14550dcb20d0fdf99e2162525c7252eab9ca3423.wav'
  },
  {
    name: 'WLS M #1 (LQ)',
    value: 'Geraint',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/geraint.4aa21b628bd99741c0275c77d8daa0ff7290265e.mp3'
  },
  {
    name: 'AUS F #2 (LQ)',
    value: 'Nicole',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/nicole.8656c0be485fb3c43c29a7cc799960211fa224e5.mp3'
  },
  {
    name: 'IND F #1 (LQ)',
    value: 'Aditi',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/aditi.09b7fbaf5620f9b49b6b759f6b2df58fdcbc5d3e.mp3'
  },
  {
    name: 'IND F #2 (LQ)',
    value: 'Raveena',
    voice:
      'https://d1.awsstatic.com/product-marketing/Polly/voices/raveena.1819674fbbf0720fdf94018f1df918ade38ebf5a.mp3'
  }
]

const playVoice = () => {
  const voiceUrl = voiceOptions.find(
    (voice) =>
      voice.value ===
      (route.params.characterId === 'new'
        ? contentStore?.editingCharacter?.voice
        : currentEditingCharacter.voice)
  )?.voice

  if (voiceUrl) {
    const audio = new Audio(voiceUrl)

    audio.play()?.catch(() => {
      // silently disregard .pause() errors
    })
  }
}
</script>

<template>
  <div class="flex flex-col gap-y-5">
    <div class="flex w-[620px] flex-col">
      <div
        :class="[
          'relative mb-5 h-[120px] w-[120px] overflow-hidden rounded-full',
          { 'cursor-pointer': !isReadOnly }
        ]"
        @click="editAvatar"
      >
        <div
          v-if="!isReadOnly"
          class="absolute bottom-0 flex h-[24px] w-full items-center justify-center bg-sc-grey-800 opacity-60"
        />
        <p
          v-if="!isReadOnly"
          class="absolute bottom-0 flex w-full items-center justify-center py-1 text-xs text-white"
        >
          Edit
        </p>

        <img
          v-if="currentEditingCharacter.avatar_url"
          class="h-full w-full object-cover"
          :src="currentEditingCharacter.avatar_url"
          :alt="`${currentEditingCharacter.given_name} ${currentEditingCharacter.family_name}`"
        />
        <div
          v-else
          class="flex h-full w-full items-center justify-center bg-sc-grey-300 text-white"
        >
          <UserIcon class="h-14 w-14" />
        </div>

        <CustomModal :model-value="imgModalIsOpen">
          <div class="flex flex-col gap-5">
            <h3 class="text-xl">Character Avatar</h3>

            <InputFile
              label="Upload Image"
              required
              :upload-file="uploadFile"
              :accepts-formats="SUPPORTED_IMAGE_MIMETYPES"
              :src="modalImage"
              :mime="modalMime"
              :loading="modalFileLoading"
              :delete-file="deleteFile"
            />
            <div class="flex gap-3 self-end">
              <CustomButton button-type="admin-secondary" @click="imgModalIsOpen = false">
                Cancel
              </CustomButton>
              <CustomButton :disabled="!modalImage" @click="setImage"> Save </CustomButton>
            </div>
          </div>
        </CustomModal>
      </div>
      <div class="flex gap-3">
        <CustomInput
          v-model="currentEditingCharacter.given_name"
          field="given_name"
          label="First Name"
          :read-only="isReadOnly"
        />
        <CustomInput
          v-model="currentEditingCharacter.family_name"
          :read-only="isReadOnly"
          label="Last Name"
        />
      </div>
      <div class="flex gap-3">
        <CustomInput
          v-model="currentEditingCharacter.internal_label"
          :read-only="isReadOnly"
          label="Internal Label"
        />
        <CustomInput
          v-model="currentEditingCharacter.clinician_role"
          :read-only="isReadOnly"
          label="Clinician Role"
        />
      </div>
      <CustomInput
        v-model="currentEditingCharacter.public_description"
        input-type="textarea"
        label="Description"
        :read-only="isReadOnly"
      />
      <div class="flex items-center gap-3">
        <div class="w-1/2">
          <CustomInput
            v-model="currentEditingCharacter.voice"
            :options="filteredVoiceOptions"
            placeholder="Select one..."
            input-type="select-search"
            label="Voice"
            :read-only="isReadOnly"
            @search="(search) => (voiceSearch = search)"
          />
        </div>
        <CustomButton
          button-type="admin-secondary"
          :start-icon="SpeakerWaveIcon"
          :disabled="!currentEditingCharacter.voice"
          class="mb-3"
          @click="playVoice"
        >
          Listen
        </CustomButton>
      </div>
      <CustomButton
        v-if="route.params.characterId !== 'new' && !isReadOnly"
        button-type="admin-primary"
        :loading="saveLoading"
        :disabled="canSave"
        class="mb-3 mt-5"
        @click="saveCharacterConfig"
      >
        Save Config
      </CustomButton>
    </div>
  </div>
</template>
