import { Environments } from './types/utils'
import type { Mimetype, MimetypeMapping } from '@/types/filetypes.d'

// env, environments, secrets

export const IS_PRODUCTION: boolean = (() => import.meta.env.VITE_ENV === Environments.PROD)()

export const IS_LOCAL: boolean = (() => import.meta.env.VITE_ENV === Environments.LOCAL)()

export const POSTHOG_KEY: string = (() => {
  return import.meta.env.VITE_POSTHOG_KEY ?? ''
})().toString()

export const POSTHOG_HOST: string = (() => {
  return import.meta.env.VITE_POSTHOG_HOST ?? ''
})().toString()

export const HILBERT_URL: string = (() => {
  return import.meta.env.VITE_SC_HILBERT_URL ?? ''
})().toString()

export const AUTH0_DOMAIN: string = (() => {
  return import.meta.env.VITE_AUTH0_DOMAIN ?? ''
})().toString()

export const AUTH0_CLIENTID: string = (() => {
  return import.meta.env.VITE_AUTH0_CLIENTID ?? ''
})().toString()

export const AUTH0_AUDIENCE: string = (() => {
  return import.meta.env.VITE_AUTH0_AUDIENCE ?? ''
})().toString()

// this is horrible, i cry
export const EMPTY_STRING_SEARCH_ALL = ''

export const LIMIT_SEARCH = 15

export const INFINITE_ATTEMPTS: number = 999999

export const MAX_SPEAKING_TIME: number = 90 * 1_000
export const TRANSCRIPTION_AUDIO_BITRATE: number = 16_000
export const MINIMUM_RESPONSE_SIZE: number = 280

export const MAX_FILE_SIZE_IN_MB: number = 2
export const MAX_FILE_SIZE: number = MAX_FILE_SIZE_IN_MB * 1024 * 1024

export const mimeExts: MimetypeMapping = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'audio/mpeg': ['.mp3']
}
export const SUPPORTED_IMAGE_MIMETYPES: Mimetype[] = ['image/png', 'image/jpeg']
export const SUPPORTED_AUDIO_MIMETYPES: Mimetype[] = ['audio/mpeg']
export const SUPPORTED_IMAGE_AND_AUDIO_MIMETYPES: Mimetype[] =
  SUPPORTED_IMAGE_MIMETYPES.concat(SUPPORTED_AUDIO_MIMETYPES)

// Defining .xls/.xlsx content types: https://stackoverflow.com/a/11834872
// Can add any formats supported by SheetJS: https://docs.sheetjs.com/docs/miscellany/formats
export const SUPPORTED_SPREADSHEET_FORMATS =
  '.xls, application/vnd.ms-excel, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, text/csv'

// temporary
export const appLogoColor = '#F6956A'

// The amount of time, in milliseconds, to wait between subsequent calls to the
// `GetGradedRubric` endpoint.
export const GET_GRADED_RUBRIC_POLLING_PERIOD = 5_000

// Format strings for dayjs
export const DAYJS_FORMAT_LONG_DATETIME = 'D MMMM YYYY, h:mma'
export const DAYJS_FORMAT_SHORT_DATE = 'MMM D'
export const DAYJS_FORMAT_SHORT_DATETIME = 'MMM D, h:mma'

// Form field descriptions
// TODO: Consider src/components/modern/ui/auto-form/interface.ts. It exports a
// ConfigItem interface type that looks like:
// `{ label?: string; description?: string; hideLabel?: boolean; component?: Component }`
// It might make more sense to define constant ConfigItems rather than individual
// strings for each field property.

export const ACTIVITY_FORM_DESCRIPTION_FIELD_DESCRIPTION =
  "Key points, learning objectives, or any other information that will help you quickly identify and understand the activity's purpose when browsing or searching. This is only shown to educators."
export const ACTIVITY_FORM_INSTRUCTIONS_FIELD_DESCRIPTION =
  'Guidance on how to approach the activity, what to expect, any specific steps to follow, or any other information that will help craft the learning experience and set the right context for the activity. This will be shown to learners before they begin.'

export const COHORT_FORM_NAME_FIELD_DESCRIPTION = 'This will be visible to learners.'
export const COHORT_FORM_ISDEFAULT_FIELD_LABEL = 'Add new accounts to this cohort?'
export const COHORT_FORM_ISDEFAULT_FIELD_DESCRIPTION =
  'When checked, all new accounts created in your organization will automatically be added to this cohort.'
