<script setup lang="ts">
import GradedRubric, { type GradedRubricProps } from '@/components/GradedRubric.vue'
import type { ConversationTaskMeta } from '@/types/api'
import { onBeforeMount } from 'vue'
import { reactive } from 'vue'
import { useAssignmentStore } from '@/stores/assignment'
import Api from '@/open-api'
import { useRouter } from 'vue-router'

definePage({
  name: 'Cohort Assignment Task Rubric',
  meta: {
    permissionLevel: 'Educator'
  }
})

const assignmentStore = useAssignmentStore()
const router = useRouter()

const rubric = reactive({
  loading: false,
  graded: null as GradedRubricProps
})

onBeforeMount(() => {
  rubric.loading = true

  if (
    !(assignmentStore.currentCohortTask.meta as ConversationTaskMeta)?.ConversationTask.rubric_id
  ) {
    router.push({ name: 'Cohorts' })
  }

  Api.Content.getRubricEndpoint(
    (assignmentStore.currentCohortTask.meta as ConversationTaskMeta).ConversationTask.rubric_id
  )
    .then((res) => {
      rubric.graded = res as GradedRubricProps
    })
    .catch(() => {
      router.push({ name: 'Cohorts' })
    })
    .finally(() => {
      rubric.loading = false
    })
})
</script>

<template>
  <GradedRubric :ungraded="true" class="px-5" :rubric="rubric.graded" />
</template>
