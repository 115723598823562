<script setup lang="ts">
definePage({
  name: 'Modern - Learner Activity View',
  meta: {
    permissionLevel: 'Student'
  }
})
</script>

<template>
  <div>My Activity instance</div>
</template>
