<script setup lang="ts">
import type { SectionTemplateOutput } from '@/open-api/generated'
import { ref, onBeforeMount, reactive, computed, watch } from 'vue'
import Api from '@/open-api'
import CustomList, { type ListData } from '@/components/CustomList.vue'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import { useAuthStore } from '@/stores/auth'
import { useUtilsStore } from '@/stores/utils'
import { useRouter, useRoute } from 'vue-router'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'
import { usePageHeadingStore } from '@/stores/pageHeading'

definePage({
  name: 'Sections',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

type SectionList = { public_label: string; minimum_score: number; mandatory: boolean }[] | []

// ==================================================
// Init
// ==================================================
const router = useRouter()
const route = useRoute('Sections')
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const utilsStore = useUtilsStore()
const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Sections')

// Watch for organization change
watch(
  () => utilsStore.organizationId,
  () => {
    section.pagination.page = 1
    setSectionsList(listSearch.value)
  }
)

// ==================================================
// Sections
// ==================================================
const section = reactive({
  loading: false,
  list: [] as SectionTemplateOutput[],
  pagination: { items_per_page: 15, page: 1, total: 0 },
  tableHeaders: [
    {
      name: 'Internal Label',
      value: 'internal_label'
    },
    {
      name: 'Minimum Score',
      value: 'minimum_score'
    },
    {
      name: 'Mandatory',
      value: 'mandatory'
    },
    {
      name: 'Actions',
      value: 'action-view'
    }
  ]
})

onBeforeMount(async () => {
  let querySearch = EMPTY_STRING_SEARCH_ALL

  if (route.query.search) {
    listSearch.value = route.query.search as string
    querySearch = route.query.search as string
  }

  setSectionsList(querySearch)
})

const listSearch = ref(EMPTY_STRING_SEARCH_ALL)

const setSectionsList = async (search = EMPTY_STRING_SEARCH_ALL) => {
  section.loading = true
  if (search !== listSearch.value) {
    section.pagination.page = 1
  }
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }

  listSearch.value = search

  try {
    const sections = await Api.Content.listSectionTemplatesEndpoint(
      search,
      section.pagination.items_per_page,
      section.pagination.page,
      authStore.isSuperAdminUser ? utilsStore.organizationId : undefined
    )

    router.push({
      query: {
        ...route.query,
        search: listSearch.value
      }
    })
    section.pagination.total = sections.pagination.total_pages || 1

    section.list = sections.section_templates
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    section.loading = false
  }
}

const shownList = computed((): SectionList | [] => {
  if (!section.list.length) {
    return []
  }
  const list = section.list.map((section) => {
    return {
      internal_label: section.internal_label,
      public_label: section.public_label,
      minimum_score: section.minimum_score,
      mandatory: section.mandatory,
      section_template_id: section.section_template_id
    }
  })
  return list
})

const createSection = async () => {
  router.push({
    name: 'Section View',
    params: { sectionTemplateId: 'new' },
    query: { mode: 'create' }
  })
}

const changePage = (page: number) => {
  section.pagination.page = page
  setSectionsList(listSearch.value)
}

const viewRouteDetails = (section_template_id: string) => {
  return {
    name: 'Section View',
    params: { sectionTemplateId: section_template_id }
  }
}

const onView = (data: { id: string; index: number }) => {
  router.push({
    name: 'Section View',
    params: { sectionTemplateId: data.id }
  })
}
</script>

<template>
  <CustomList
    create-button="Create Section"
    :pagination="section.pagination"
    :has-list-options="false"
    :list-headers="section.tableHeaders"
    :list-data="shownList"
    :has-header="true"
    :loading="section.loading"
    :search="listSearch"
    :generate-view-link="(data: ListData) => viewRouteDetails(data.section_template_id as string)"
    @on-view="onView"
    @on-search="(search: string) => setSectionsList(search)"
    @on-create="createSection"
    @on-change-page="(page: number) => changePage(page)"
  >
  </CustomList>
</template>
