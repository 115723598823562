<script setup lang="ts">
import { computed } from 'vue'
import { ScrollArea } from '@/components/modern/ui/scroll-area'
import OrganizationSelect from './OrganizationSelect.vue'
import CollapsibleNav from './CollapsibleNav.vue'
import {
  Cog8ToothIcon,
  UsersIcon,
  LightBulbIcon,
  ClipboardDocumentListIcon,
  AcademicCapIcon,
  BriefcaseIcon,
  BookOpenIcon,
  PencilSquareIcon,
  BuildingOffice2Icon
} from '@heroicons/vue/24/outline'

import { useAuthStore } from '@/stores/auth'
import InfoMenu from './InfoMenu.vue'
import RoleSelect from './RoleSelect.vue'
import NavItem from './NavItem.vue'
import AppBrainIcon from '@/components/AppBrainIcon.vue'

const authStore = useAuthStore()

// TODO: Assignment Authoring route goes here
const authoringRoutes = computed(() => [
  { label: 'Characters', to: '/content/characters', icon: UsersIcon },
  { label: 'Rubrics', to: '/content/rubrics', icon: LightBulbIcon },
  { label: 'Sections', to: '/content/sections', icon: ClipboardDocumentListIcon },
  ...(authStore.flagModernEnabled || authStore.isSuperAdminUser
    ? [{ label: 'Activities', to: '/activities/', icon: PencilSquareIcon }]
    : [])
])

const administrationRoutes = [{ label: 'Settings', to: '/management', icon: Cog8ToothIcon }]

const myActivitiesRoute = { label: 'My Activities', to: '/my-activities', icon: BriefcaseIcon }

const legacyRoutes = computed(() => [
  { label: 'My Assignments', to: '/my-assignments', icon: BriefcaseIcon },
  { label: 'My History', to: '/history', icon: BookOpenIcon },
  ...(authStore.isAtLeastEducatorUser
    ? [
        { label: 'Cohorts', to: '/cohort', icon: AcademicCapIcon },
        { label: 'Organization History', to: '/organization/history', icon: BuildingOffice2Icon }
      ]
    : [])
])
</script>

<template>
  <aside class="flex h-full flex-col items-start justify-start space-y-3 p-2 text-sm">
    <OrganizationSelect class="pb-1" />
    <ScrollArea class="w-full flex-grow">
      <div class="flex h-full flex-col gap-1">
        <NavItem
          v-if="authStore.flagModernEnabled || authStore.isSuperAdminUser"
          :route="myActivitiesRoute"
        ></NavItem>
        <CollapsibleNav
          v-if="authStore.flagLegacyEnabled || authStore.isSuperAdminUser"
          :deprecated="true"
          label=""
          :routes="legacyRoutes"
          :default-open="true"
        />
        <CollapsibleNav
          v-if="
            (authStore.flagAuthoringEnabled && authStore.isAtLeastEducatorUser) ||
            authStore.isSuperAdminUser
          "
          label="Author"
          :routes="authoringRoutes"
          :default-open="true"
        />
        <CollapsibleNav
          v-if="authStore.isAtLeastInstitutionAdminUser"
          label="Administer"
          :routes="administrationRoutes"
          :default-open="true"
        />
        <div class="flex-grow font-semibold">
          <!-- TODO: Scrollable cohorts list goes here -->
          <div v-if="authStore.flagModernEnabled || authStore.isSuperAdminUser">Cohorts</div>
        </div>
      </div>
    </ScrollArea>
    <RoleSelect v-if="authStore.isTrueSuperAdmin" />
    <span class="w-full pb-3 text-center text-xs uppercase text-muted-foreground"
      >Powered by SimConverse</span
    >
    <div class="flex w-full items-center justify-between">
      <InfoMenu class="size-9" />
      <AppBrainIcon class="size-9" />
    </div>
  </aside>
</template>
