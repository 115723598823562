<script setup lang="ts">
import type { CohortSummary } from '@/open-api/generated'
import { ref, onBeforeMount, reactive } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import Api from '@/open-api'
import CustomList, { type ListData } from '@/components/CustomList.vue'
import { NotificationStatus } from '@/types/notification'
import { useAssignmentStore } from '@/stores/assignment'
import { useUtilsStore } from '@/stores/utils'
import { watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'
import CohortVisibilityIndicator from '@/components/CohortVisibilityIndicator.vue'
import { usePageHeadingStore } from '@/stores/pageHeading'

definePage({
  name: 'Cohorts',
  meta: {
    permissionLevel: 'Educator'
  }
})

// ==================================================
// Init
// ==================================================
const router = useRouter()
const route = useRoute('Cohorts')

const notificationStore = useNotificationStore()
const assignmentStore = useAssignmentStore()
const utilsStore = useUtilsStore()
const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Cohorts')

// ==================================================
// Cohorts
// ==================================================
const cohort = reactive({
  loading: false,
  list: [] as CohortSummary[],
  pagination: { items_per_page: 15, page: 1, total: 0 },
  tableHeaders: [
    {
      name: 'Cohort Name',
      value: 'name'
    },
    {
      name: 'Visibility',
      value: 'custom'
    },
    {
      name: 'Actions',
      value: 'action-view'
    }
  ]
})

const listSearch = ref(EMPTY_STRING_SEARCH_ALL)

onBeforeMount(async () => {
  let querySearch = EMPTY_STRING_SEARCH_ALL

  if (route.query.search) {
    listSearch.value = route.query.search as string
    querySearch = route.query.search as string
  }

  setCohortList(querySearch)
})

const setCohortList = async (search = EMPTY_STRING_SEARCH_ALL) => {
  if (utilsStore.organizationId) {
    cohort.loading = true
    if (search !== listSearch.value) {
      cohort.pagination.page = 1
    }
    if (search === '') {
      search = EMPTY_STRING_SEARCH_ALL
    }

    listSearch.value = search

    try {
      const cohorts = await Api.Assignment.searchCohortsEndpoint(
        utilsStore.organizationId,
        search,
        cohort.pagination.items_per_page,
        cohort.pagination.page
      )

      router.push({
        query: {
          ...route.query,
          search: listSearch.value
        }
      })
      cohort.pagination.total = cohorts.pagination.total_pages || 1
      cohort.list = cohorts.cohorts
    } catch (err: any) {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    } finally {
      cohort.loading = false
    }
  }
}

// Watch for organization change
watch(
  () => utilsStore.organizationId,
  () => {
    cohort.pagination.page = 1
    setCohortList(listSearch.value)
  }
)

const createCohort = async () => {
  // Init editing cohort & set organization id
  assignmentStore.setEditingCohort({ organization_id: utilsStore.organizationId })

  router.push({
    name: 'Cohort Details',
    params: { cohortId: 'new' },
    query: { mode: 'create' }
  })
}

const listView = ref('table')

const changePage = (page: number) => {
  cohort.pagination.page = page
  setCohortList(listSearch.value)
}

const viewRouteDetails = (cohort_id: string) => {
  return {
    name: 'Cohort Assignments',
    params: { cohortId: cohort_id }
  }
}

const onView = (data: { id: string; index: number }) => {
  // Init editing cohort & set organization id
  assignmentStore.setEditingCohort({ organization_id: utilsStore.organizationId })

  router.push({
    name: 'Cohort Assignments',
    params: { cohortId: data.id }
  })
}
</script>

<template>
  <CustomList
    create-button="Create Cohort"
    :pagination="cohort.pagination"
    :has-list-options="false"
    :view-type="listView"
    :list-headers="cohort.tableHeaders"
    :list-data="cohort.list"
    :has-header="true"
    :cell-add="false"
    :loading="cohort.loading"
    :generate-view-link="(data: ListData) => viewRouteDetails(data.cohort_id as string)"
    @on-list-view-change="(view: string) => (listView = view)"
    @on-view="onView"
    @on-search="(search: string) => setCohortList(search)"
    @on-create="createCohort"
    @on-change-page="(page: number) => changePage(page)"
  >
    <template #custom="{ data }: { data: any }">
      <CohortVisibilityIndicator :visible="data.visible" />
    </template>
  </CustomList>
</template>
