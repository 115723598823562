<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetAssignmentInstanceStudentInsights from '@/composables/api/queries/useGetAssignmentInstanceStudentInsights'
import {
  type AssignmentInstanceStudentProgress,
  AssignmentInstanceStudentStatusApiEnum
} from '@/open-api/generated'
// import useDismissAllActivityTaskAttempts from '@/composables/api/mutations/useDismissAllActivityTaskAttempts'
// import useExemptStudentFromActivity from '@/composables/api/mutations/useExemptStudentFromActivity'
// import { CommandState } from '@/composables/api/mutations/types'
import type { Table } from '@tanstack/vue-table'
import {
  DataTable,
  DataTableControls,
  DataTableGroupTitle,
  DataTableGroupEmpty
} from '@/components/modern/tables'
import { useColumns } from '@/components/modern/tables/instance-students'
import { Button } from '@/components/modern/ui/button'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import {
  DownloadIcon,
  CircleIcon,
  PieChartIcon,
  CheckCircledIcon,
  CrossCircledIcon
} from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Activity Instance',
  meta: {
    permissionLevel: 'Educator'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Activity Learners')

const route = useRoute('Modern Cohorts - Activity Instance')
const cohortId = computed(() => route.params.cohortId)
const assignmentInstanceId = computed(() => route.params.assignmentInstanceId)

const notificationStore = useNotificationStore()

const { isLoading, assignmentInstanceStudentInsights } = useGetAssignmentInstanceStudentInsights({
  assignmentInstanceId,
  notificationStore
})
const tasksTotal = computed(() => assignmentInstanceStudentInsights.value?.tasks_total ?? 0)

// const { execute } = useDismissAllActivityTaskAttempts({ })
const voidAllAttempts = ({ user }: AssignmentInstanceStudentProgress) => {
  console.log('voidAllAttempts for ', user.email)
}
// const { execute } = useExemptStudentFromActivity({ })
// const exemptFromActivity = ({ user }: AssignmentInstanceStudentProgress) => {
//   console.log('exemptFromActivity for ', user.email)
// }

const instanceStudents = useColumns({
  cohortId,
  assignmentInstanceId,
  tasksTotal,
  voidAllAttempts
})

// About `ref<Table<unknown> | null>(null)`:
// Template ref to access the `table` instance, which is exposed by `defineExpose` on DataTable
// This is the cleanest way to pass multiple child tables to a single DataTableControls component
// https://vuejs.org/api/sfc-script-setup.html#defineexpose
// https://vuejs.org/guide/typescript/composition-api.html#typing-template-refs

const passedTableRef = ref<Table<unknown> | null>(null)
const passedData = computed(
  () =>
    assignmentInstanceStudentInsights.value?.status.find(
      (s) => s.type === AssignmentInstanceStudentStatusApiEnum.PASS
    )?.payload
)
const passedEmpty = computed<boolean>(
  () => passedData.value === undefined || passedData.value.length === 0
)

const failedTableRef = ref<Table<unknown> | null>(null)
const failedData = computed(
  () =>
    assignmentInstanceStudentInsights.value?.status.find(
      (s) => s.type === AssignmentInstanceStudentStatusApiEnum.FAIL
    )?.payload
)
const failedEmpty = computed<boolean>(
  () => failedData.value === undefined || failedData.value.length === 0
)

const inProgressTableRef = ref<Table<unknown> | null>(null)
const inProgressData = computed(
  () =>
    assignmentInstanceStudentInsights.value?.status.find(
      (s) => s.type === AssignmentInstanceStudentStatusApiEnum.IN_PROGRESS
    )?.payload
)
const inProgressEmpty = computed<boolean>(
  () => inProgressData.value === undefined || inProgressData.value.length === 0
)

const notStartedTableRef = ref<Table<unknown> | null>(null)
const notStartedData = computed(
  () =>
    assignmentInstanceStudentInsights.value?.status.find(
      (s) => s.type === AssignmentInstanceStudentStatusApiEnum.NOT_STARTED
    )?.payload
)
const notStartedEmpty = computed<boolean>(
  () => notStartedData.value === undefined || notStartedData.value.length === 0
)
</script>

<template>
  <div class="flex items-end justify-between">
    <p class="text-2xl font-semibold">Cohort Activities</p>

    <!-- @vue-expect-error Button does not specify `disabled` prop -->
    <Button variant="default" size="lg" disabled>
      <DownloadIcon class="mr-3 size-5" />
      Export
    </Button>
  </div>
  <template v-if="isLoading">
    <AppLoadingSpinner :loading="isLoading" center-screen />
  </template>
  <template v-else>
    <DataTableControls
      :tables="[notStartedTableRef, inProgressTableRef, passedTableRef, failedTableRef]"
    />

    <DataTableGroupTitle :empty="notStartedEmpty">
      <CircleIcon class="size-6" />
      <p class="text-lg">Not Started</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="notStartedEmpty">
      No learners are yet to start.
    </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="notStartedTableRef"
      :data="notStartedData!"
      :columns="instanceStudents"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />

    <DataTableGroupTitle :empty="inProgressEmpty">
      <PieChartIcon class="size-6" />
      <p class="text-lg">In Progress</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="inProgressEmpty"> No learners are in progress. </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="inProgressTableRef"
      :data="inProgressData!"
      :columns="instanceStudents"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />

    <DataTableGroupTitle :empty="passedEmpty">
      <CheckCircledIcon class="size-6 text-green-600" />
      <p class="text-lg">Passed</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="passedEmpty">
      No learners have a final grade of passing.
    </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="passedTableRef"
      :data="passedData!"
      :columns="instanceStudents"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />

    <DataTableGroupTitle :empty="failedEmpty">
      <CrossCircledIcon class="size-6 text-red-500" />
      <p class="text-lg">Did Not Pass</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="failedEmpty">
      No learners have a final grade of not passing.
    </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="failedTableRef"
      :data="failedData!"
      :columns="instanceStudents"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />
  </template>
</template>
