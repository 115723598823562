<script setup lang="ts">
definePage({
  name: 'Modern Cohorts - Individual Student Attempts for Activity Instance',
  meta: {
    permissionLevel: 'Educator'
  }
})
</script>

<template>
  <div>Individual Student Attempts for Activity Instance</div>
</template>
