<script setup lang="ts">
import type { OrganizationConversationHistoryEntrySummary } from '@/open-api/generated'
import { useNotificationStore } from '@/stores/notifications'
import { NotificationStatus } from '@/types/notification'
import { ref, onBeforeMount, reactive, watch, onMounted, onUnmounted } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import CustomList from '@/components/CustomList.vue'
import { useUtilsStore } from '@/stores/utils'
import Api from '@/open-api'
import ConversationCompletionIndicator from '@/components/ConversationCompletionIndicator.vue'
import CustomButton from '@/components/utils/CustomButton.vue'
import { useRouter } from 'vue-router'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'
import { VR_SCENES_CHARACTER_IDS } from '@/vr/vr-constants'
import type { VrConversationOutput } from '@/vr/types/vr'
import { companionConversationsOf, getParentTaskAttemptId } from '@/utils/vr'
import { useSimulationStore } from '@/stores/simulation'
import { usePageHeadingStore } from '@/stores/pageHeading'

definePage({
  name: 'Organization History',
  meta: {
    permissionLevel: 'Educator'
  }
})
// ==================================================
// Init
// ==================================================
const router = useRouter()
const utilsStore = useUtilsStore()
const notificationStore = useNotificationStore()
const simulationStore = useSimulationStore()
const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Organization History')

// Given an ISO 8601 timestamp, formats it in the format "MMM DD, HH:MM[am|pm]".
function formatDateTime(formatMe: string) {
  const date = new Date(formatMe)
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
}

watch(
  () => utilsStore.organizationId,
  () => {
    conversationHistory.pagination.page = 1
    setConversationList(search.value)
  }
)

// ==================================================
// Table Management
// ==================================================
onMounted(() => {
  if (utilsStore.isMobile) {
    conversationHistory.tableHeaders = [
      {
        name: 'Character',
        value: 'given_name'
      },
      {
        name: 'Status',
        value: 'custom'
      }
    ]
  } else {
    conversationHistory.tableHeaders = [
      {
        name: 'Character',
        value: 'given_name'
      },
      {
        name: 'Email',
        value: 'email'
      },
      {
        name: 'Task',
        value: 'task_name'
      },
      {
        name: 'Assignment',
        value: 'assignment_name'
      },
      {
        name: 'Cohort',
        value: 'cohort_name'
      },
      {
        name: 'Started on',
        value: 'started_at'
      },
      {
        name: 'Status',
        value: 'custom'
      },
      {
        name: 'Actions',
        value: 'action-view'
      }
    ]
  }
})

onUnmounted(() => {
  utilsStore.setHistoryUserId(undefined)
})

// ==================================================
// Conversations
// ==================================================
const conversationHistory = reactive({
  loading: false,
  list: [] as OrganizationConversationHistoryEntrySummary[],
  pagination: { items_per_page: 15, page: 1, total: 0 },
  tableHeaders: [] as { name: string; value: string }[]
})

onBeforeMount(async () => {
  setConversationList(search.value)
})

const search = ref(EMPTY_STRING_SEARCH_ALL)

const setConversationList = async (searchString: string) => {
  conversationHistory.loading = true
  if (!utilsStore.organizationId) {
    return
  }

  if (searchString === '') {
    searchString = EMPTY_STRING_SEARCH_ALL
  }

  search.value = searchString

  try {
    const conversations = await Api.Conversation.listOrganizationConversationsEndpoint(
      utilsStore.organizationId,
      searchString,
      conversationHistory.pagination.items_per_page,
      conversationHistory.pagination.page,
      utilsStore.historyUserId || undefined
    )

    conversationHistory.list = conversations.conversations.map((conv) => ({
      ...conv,
      started_at: formatDateTime(conv.started_at),
      ended_at: conv.ended_at ? formatDateTime(conv.ended_at) : '',
      status: !!conv.ended_at
    }))

    conversationHistory.pagination.total = conversations.pagination.total_pages || 1
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    conversationHistory.loading = false
  }
}

const changePage = (page: number) => {
  conversationHistory.pagination.page = page
  setConversationList(search.value)
}

const onView = (data: { id: string; index: number }) => {
  const found = conversationHistory.list.find((conv) => conv.conversation_id === data.id)
  if (found) {
    // Cordoning: only runs this logic if the character_id is found in the hardcoded list of VR character ids
    if (VR_SCENES_CHARACTER_IDS.includes(found.character_id)) {
      // Completed vr conversation
      // find all other conversations that
      //  1. ended at this time
      //  2. are by the same user_id
      // ... in order to recreate simulationStore.vrConversation
      const convBundle = conversationHistory.list.filter(companionConversationsOf(found))
      simulationStore.setVrConversation(convBundle as unknown as VrConversationOutput[])
      const taskAttemptId = getParentTaskAttemptId(convBundle)

      if (taskAttemptId) {
        router.push({
          name: 'MyAssignments Conversation Transcript',
          params: { conversationId: 'vr-scene', taskAttemptId }
        })
      } else {
        notificationStore.addINFO('The parent task for this conversation is on the next page.')
      }
    } else {
      // Completed regular conversation
      router.push({
        name: 'Organization History Transcript',
        params: { conversationId: data.id, taskAttemptId: found.conversation_task_attempt_id }
      })
    }
  } else {
    notificationStore.addNotification({
      subtitle: 'Conversation does not exist.',
      status: NotificationStatus.DANGER
    })
  }
}
</script>

<template>
  <CustomList
    view-type="table"
    :pagination="conversationHistory.pagination"
    :has-list-options="false"
    :list-headers="conversationHistory.tableHeaders"
    :list-data="conversationHistory.list"
    :has-header="true"
    :loading="conversationHistory.loading"
    @on-view="onView"
    @on-change-page="(page: number) => changePage(page)"
    @on-search="(search: string) => setConversationList(search)"
  >
    <template #filterList>
      <CustomButton
        v-if="utilsStore.historyUserId"
        button-type="grey"
        button-size="sm"
        :end-icon="XMarkIcon"
        @click="
          () => {
            utilsStore.setHistoryUserId(undefined)
            setConversationList(EMPTY_STRING_SEARCH_ALL)
          }
        "
      >
        {{ utilsStore.historyUserId }}
      </CustomButton>
    </template>

    <template #custom="{ data }: { data: any }">
      <ConversationCompletionIndicator :completed="data.status" />
    </template>
  </CustomList>
</template>
