<script lang="ts" setup>
import { ref, watch, computed } from 'vue'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/modern/ui/select'
import { BuildingOffice2Icon } from '@heroicons/vue/24/outline'
import { cn } from '@/lib/utils'
import { useUtilsStore } from '@/stores/utils'
import useAllOrganizations from '@/composables/api/useAllOrganizations'
import { useAuth0 } from '@auth0/auth0-vue'
import { useAuthStore } from '@/stores/auth'

const { user } = useAuth0()
const utilsStore = useUtilsStore()
const authStore = useAuthStore()

if (!utilsStore.organizationId) {
  utilsStore.setOrganizationId(user.value?.organization_id as string)
}

const { organizations } = useAllOrganizations()

// casting as string because this component can only render for a logged in user
const currentOrganizationId = ref<string>(
  utilsStore.organizationId || (user.value?.organization_id as string)
)

const currentOrganization = computed(() =>
  organizations.value.find((org) => org.organization_id === currentOrganizationId.value)
)

watch(
  currentOrganizationId,
  () => {
    utilsStore.setOrganizationId(currentOrganizationId.value)
  },
  { immediate: true }
)
</script>

<template>
  <div class="flex w-full flex-col space-y-1">
    <div class="flex items-center gap-1 px-2 text-base font-semibold">
      <BuildingOffice2Icon class="size-4 flex-none" />
      <div>{{ currentOrganization?.name }}</div>
    </div>
    <Select v-if="authStore.isSuperAdminUser" v-model="currentOrganizationId">
      <SelectTrigger
        aria-label="admin organization select"
        :class="
          cn(
            'flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0'
          )
        "
      >
        <SelectValue placeholder="Select an organization" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem
          v-for="organization of organizations"
          :key="organization.organization_id"
          :value="organization.organization_id"
        >
          <div
            class="flex items-center gap-3 [&_svg]:size-4 [&_svg]:shrink-0 [&_svg]:text-foreground"
          >
            {{ organization.name }}
          </div>
        </SelectItem>
      </SelectContent>
    </Select>
  </div>
</template>
