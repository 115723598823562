<script setup lang="ts">
import type { CharacterSummary } from '@/open-api/generated'
import { ref, onBeforeMount, reactive, watch } from 'vue'
import Api from '@/open-api'
import CustomList, { type ListData } from '@/components/CustomList.vue'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import { useUtilsStore } from '@/stores/utils'
import { NotificationStatus } from '@/types/notification'
import { useRouter, useRoute } from 'vue-router'
import { EMPTY_STRING_SEARCH_ALL } from '@/constants'
import { usePageHeadingStore } from '@/stores/pageHeading'

definePage({
  name: 'Characters',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute('Characters')
const router = useRouter()
const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Characters')
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const utilsStore = useUtilsStore()

// Watch for organization change
watch(
  () => utilsStore.organizationId,
  () => {
    character.pagination.page = 1
    setCharacterList(listSearch.value)
  }
)

// ==================================================
// Characters
// ==================================================
const character = reactive({
  loading: false,
  list: [] as CharacterSummary[],
  pagination: { items_per_page: 15, page: 1, total: 0 },
  tableHeaders: [
    {
      name: 'Internal Label',
      value: 'internal_label'
    },
    {
      name: 'Label',
      value: 'given_name'
    },
    {
      name: 'Description',
      value: 'public_description'
    },
    {
      name: 'Actions',
      value: 'action-view'
    }
  ]
})

onBeforeMount(async () => {
  let querySearch = EMPTY_STRING_SEARCH_ALL

  if (route.query.search) {
    listSearch.value = route.query.search as string
    querySearch = route.query.search as string
  }

  setCharacterList(querySearch)
})

const listSearch = ref(EMPTY_STRING_SEARCH_ALL)

const setCharacterList = async (search = EMPTY_STRING_SEARCH_ALL) => {
  character.loading = true
  if (search !== listSearch.value) {
    character.pagination.page = 1
  }
  if (search === '') {
    search = EMPTY_STRING_SEARCH_ALL
  }
  listSearch.value = search
  try {
    const characters = await Api.Content.listCharactersEndpoint(
      search,
      character.pagination.items_per_page,
      character.pagination.page,
      authStore.isSuperAdminUser ? utilsStore.organizationId : undefined
    )

    router.push({
      query: {
        ...route.query,
        search: listSearch.value
      }
    })
    character.pagination.total = characters.pagination.total_pages || 1

    character.list = characters.characters
  } catch (err: any) {
    notificationStore.addNotification({
      subtitle: err?.body?.message,
      status: NotificationStatus.DANGER
    })
  } finally {
    character.loading = false
  }
}

const createCharacter = async () => {
  router.push({
    name: 'Character Config',
    params: { characterId: 'new' },
    query: { mode: 'create' }
  })
}

const listView = ref('table')

const changePage = (page: number) => {
  character.pagination.page = page
  setCharacterList(listSearch.value)
}

const viewRouteDetails = (character_id: string) => {
  return {
    name: 'Character Config',
    params: { characterId: character_id }
  }
}

const onView = (data: { id: string; index: number }) => {
  router.push({
    name: 'Character Config',
    params: { characterId: data.id }
  })
}
</script>

<template>
  <CustomList
    create-button="Create Character"
    :pagination="character.pagination"
    :view-type="listView"
    :list-headers="character.tableHeaders"
    :list-data="character.list"
    :has-header="true"
    :cell-add="false"
    :loading="character.loading"
    :search="listSearch"
    :generate-view-link="(data: ListData) => viewRouteDetails(data.character_id as string)"
    @on-list-view-change="(view: string) => (listView = view)"
    @on-view="onView"
    @on-search="(search: string) => setCharacterList(search)"
    @on-create="createCharacter"
    @on-change-page="(page: number) => changePage(page)"
  >
  </CustomList>
</template>
