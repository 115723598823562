<script setup lang="ts">
import { watch, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useUtilsStore } from '@/stores/utils'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import type { GetCohortEducator } from '@/open-api/generated'
import useGetCohortV2 from '@/composables/api/queries/useGetCohortV2'
import useGetCohortEducators from '@/composables/api/queries/useGetCohortEducators'
import useSearchOrganizationUsers from '@/composables/api/queries/useSearchOrganizationUsers'
import useAddEducatorToCohort from '@/composables/api/mutations/useAddEducatorToCohort'
import useRemoveEducatorFromCohort from '@/composables/api/mutations/useRemoveEducatorFromCohort'
import useUpdateCohortV2, { schema } from '@/composables/api/mutations/useUpdateCohortV2'
import { CommandState } from '@/composables/api/mutations/types'
import type { z } from 'zod'
import { useForm, type GenericObject } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { AutoForm, type Config } from '@/components/modern/ui/auto-form'
import {
  COHORT_FORM_NAME_FIELD_DESCRIPTION,
  COHORT_FORM_ISDEFAULT_FIELD_LABEL,
  COHORT_FORM_ISDEFAULT_FIELD_DESCRIPTION
} from '@/constants'
import { SearchSelect } from '@/components/modern/ui/search-select'
import { DataTable } from '@/components/modern/tables'
import { useColumns } from '@/components/modern/tables/cohort-educators'
import { Button } from '@/components/modern/ui/button'
import { Label } from '@/components/modern/ui/label'
import { Separator } from '@/components/modern/ui/separator'
import { PlusIcon, ChevronLeftIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Cohort Details',
  meta: {
    permissionLevel: 'Educator'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Cohort Details')

const route = useRoute('Modern Cohorts - Cohort Details')
const cohortId = computed(() => route.params.cohortId)

const utilsStore = useUtilsStore()
const organizationId = computed(() => utilsStore.organizationId!)

const notificationStore = useNotificationStore()

// Form setup

const { cohort, refetch: refetchCohort } = useGetCohortV2({ cohortId, notificationStore })
const { state, execute, reset } = useUpdateCohortV2({
  cohortId,
  notificationStore
})

const form = useForm({
  validationSchema: toTypedSchema(schema)
})

const fieldConfig: Config<z.infer<typeof schema>> = {
  name: {
    description: COHORT_FORM_NAME_FIELD_DESCRIPTION
  },
  isDefault: {
    label: COHORT_FORM_ISDEFAULT_FIELD_LABEL,
    description: COHORT_FORM_ISDEFAULT_FIELD_DESCRIPTION
  }
}

// Fill in the form with the server values from GetCohort
watch(cohort, () => {
  form.resetForm({ values: mapCohortToFormValues(cohort) })
})

// Map the output of GetCohort to the input of UpdateCohort
const mapCohortToFormValues = (arg: typeof cohort): z.infer<typeof schema> | undefined => {
  if (!arg.value) {
    return undefined
  }
  return {
    name: arg.value.name,
    isDefault: arg.value.is_default
  }
}

const onSubmit = form.handleSubmit(async (values) => {
  await execute({ isDefault: false, ...values })
  // On success, refetch GetCohort to ensure the form is filled with the values
  // the server was actually updated with.
  if (state.value === CommandState.SUCCESS) {
    refetchCohort()
  }
  // Reset the mutation so users can make further changes.
  reset()
}) as (e: GenericObject) => Promise<void>

// Table setup
const {
  cohortEducators: educators,
  refetch: refetchEducators,
  isLoading
} = useGetCohortEducators({ cohortId, notificationStore })

const { execute: executeRemove } = useRemoveEducatorFromCohort({ notificationStore })
const removeEducator = async (educator: GetCohortEducator) => {
  await executeRemove({ userId: educator.id, cohortId: cohortId.value })
  refetchEducators()
}

const cohortEducators = useColumns({ removeEducator })

// Action setup
const query = ref<string>('')
const { isLoading: usersLoading, users } = useSearchOrganizationUsers({
  organizationId,
  query,
  notificationStore
})
const userId = ref<string>()

const { execute: executeAdd } = useAddEducatorToCohort({ notificationStore })
const addEducator = async () => {
  if (userId.value) {
    await executeAdd({ userId: userId.value, cohortId: cohortId.value })
    userId.value = undefined
    refetchEducators()
  }
}
</script>

<template>
  <Button class="mb-6 w-min" variant="outline" as-child>
    <RouterLink :to="{ name: 'Modern Cohorts - Cohort Activities List', params: { cohortId } }">
      <ChevronLeftIcon class="size-4" />
      Back to All Cohorts
    </RouterLink>
  </Button>
  <div class="flex w-5/6 max-w-2xl flex-col self-center">
    <p class="mb-4 text-xl font-medium">Details</p>
    <AutoForm :schema="schema" :form="form" :field-config="fieldConfig" @submit="onSubmit">
      <Button>Save Changes</Button>
    </AutoForm>
    <Separator class="my-8" />
    <p class="mb-5 text-xl font-medium">Educators</p>
    <div class="flex flex-col gap-2">
      <Label for="search-educators">Search for an educator to add to this cohort</Label>
      <div class="flex flex-row gap-2">
        <SearchSelect
          id="search-educators"
          v-model:query="query"
          v-model:selected-value="userId"
          :data="users"
          value-key="id"
          label-key="email"
          placeholder-label="user"
          placeholder-label-plural="users"
          :loading="usersLoading"
        />
        <Button variant="default" @click="addEducator">
          <PlusIcon class="size-4" />
          Add Educator
        </Button>
      </div>
    </div>
    <DataTable
      :data="educators"
      :columns="cohortEducators"
      :loading="isLoading"
      disable-pagination
    />
  </div>
</template>
