<script setup lang="ts">
import type {
  CreateSectionTemplateInput,
  ItemTemplate,
  SectionTemplateOutput
} from '@/open-api/generated'
import type {
  RubricItemPayloadQuestion,
  RubricItemPayloadAction,
  RubricItemPayloadInvestigation
} from '@/types/api'
import { onBeforeMount, ref, computed } from 'vue'
import { NotificationStatus } from '@/types/notification'
import CustomButton from '@/components/utils/CustomButton.vue'
import CustomDeleteModal from '@/components/utils/CustomDeleteModal.vue'
import { DocumentDuplicateIcon, LockOpenIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { useContentStore } from '@/stores/content'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import Api from '@/open-api'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'

definePage({
  name: 'Section',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute<'Section' | 'Section View' | 'Section Access Controls'>()
const router = useRouter()
const contentStore = useContentStore()
const notificationStore = useNotificationStore()
const authStore = useAuthStore()
const sectionLoading = ref(false)
const saveLoading = ref(false)
const isReadOnly = computed(() => {
  return (
    route.params.sectionTemplateId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingSection as SectionTemplateOutput)?.owning_organization_id
    )
  )
})

const deleteSectionModalStatus = ref(false)
const deleteSectionLoading = ref(false)

onBeforeMount(async () => {
  setCurrentSection()
})

// ==================================================
// Section
// ==================================================
const itemsErrorArr = computed((): { index: number; error: string }[] => {
  if (!contentStore.editingSection?.items?.length) {
    return []
  }
  return contentStore.editingSection.items.reduce(
    (acc: { index: number; error: string }[], item: ItemTemplate, index: number) => {
      if (
        !item.public_label.trim() ||
        (!(item.payload as RubricItemPayloadQuestion).Question?.question.trim() &&
          !(item.payload as RubricItemPayloadAction).Action?.public_label.trim() &&
          !(item.payload as RubricItemPayloadInvestigation).Investigation?.public_label.trim())
      ) {
        acc.push({ index, error: 'Missing value' })
      }
      return acc
    },
    [] as { index: number; error: string }[]
  )
})

const sectionNotComplete = computed(() => {
  const { public_label, minimum_score, items, internal_label } = contentStore.editingSection

  return (
    !public_label?.trim() ||
    !internal_label?.trim() ||
    !minimum_score ||
    minimum_score <= 0 ||
    !items ||
    minimum_score > items.length ||
    items.length === 0 ||
    !!itemsErrorArr.value.length
  )
})

const setCurrentSection = async () => {
  if (route.params.sectionTemplateId === 'new') {
    contentStore.setEditingSection(contentStore.newSection)
  } else if (
    (contentStore.editingSection as SectionTemplateOutput)?.section_template_id !==
    route.params.sectionTemplateId
  ) {
    sectionLoading.value = true
    try {
      const section = await Api.Content.getSectionTemplateEndpoint(
        route.params.sectionTemplateId as string
      )

      if (!section) {
        router.push({ name: 'Sections' })
      }

      contentStore.setEditingSection(section)
    } catch (err: any) {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    } finally {
      sectionLoading.value = false
    }
  }
}

// ==================================================
// Create Section
// ==================================================
const createSectionTemplate = () => {
  saveLoading.value = true

  Api.Content.createSectionTemplateEndpoint(
    contentStore.editingSection as CreateSectionTemplateInput
  )
    .then((res) => {
      notificationStore.addNotification({
        subtitle: 'Section successfully created',
        status: NotificationStatus.SUCCESS
      })
      router
        .push({
          name: route.name,
          params: { sectionTemplateId: res.section_template_id }
        })
        .then(async () => {
          await setCurrentSection()
        })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      saveLoading.value = false
    })
}

// ==================================================
// Duplicate Section
// ==================================================
const duplicateSectionLoading = ref(false)

const duplicateSection = async () => {
  duplicateSectionLoading.value = true

  const editingSection = contentStore.editingSection as SectionTemplateOutput
  const duplicatedSection: CreateSectionTemplateInput = {
    items: editingSection.items,
    mandatory: editingSection.mandatory,
    minimum_score: editingSection.minimum_score,
    public_label: `Copy of ${editingSection.public_label} ${dayjs().format('DD/MM/YYYY HH:mm:ss')}`,
    internal_label: `Copy of ${editingSection.internal_label} ${dayjs().format(
      'DD/MM/YYYY HH:mm:ss'
    )}`,
    allowlist: editingSection.allowlist?.map((org) => org.organization_id)
  }

  await Api.Content.createSectionTemplateEndpoint(duplicatedSection)
    .then(() => {
      router.push({
        name: 'Sections'
      })
      notificationStore.addNotification({
        subtitle: `${editingSection.public_label} successfully cloned`,
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      duplicateSectionLoading.value = false
    })
}

// ==================================================
// Delete Section
// ==================================================
const confirmDeleteSection = () => {
  deleteSectionLoading.value = true
  Api.Content.deleteSectionTemplateEndpoint({
    section_template_id: route.params.sectionTemplateId as string
  })
    .then(() => {
      deleteSectionModalStatus.value = false

      router.push({
        name: 'Sections'
      })
    })
    .catch((err: any) => {
      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
    .finally(() => {
      deleteSectionLoading.value = false
    })
}
</script>

<template>
  <div
    :key="(contentStore.editingSection as SectionTemplateOutput)?.section_template_id"
    :class="['flex h-full flex-col gap-y-5']"
  >
    <div class="sticky -top-3 z-50 mb-5 flex justify-between bg-white pt-3">
      <div class="flex flex-row items-center">
        <h1 class="mr-5 truncate pr-10 text-2xl font-medium">
          {{
            route.query?.mode === 'create'
              ? 'Create Section'
              : contentStore.editingSection.public_label
          }}
        </h1>
        <CustomButton v-if="isReadOnly" button-type="grey" button-size="sm" :can-select="false">
          READ-ONLY
        </CustomButton>
      </div>

      <div class="flex gap-3">
        <CustomButton
          v-if="route.name === 'Section Access Controls'"
          button-type="admin-secondary"
          @click="router.push({ name: 'Section View' })"
        >
          Back to Section
        </CustomButton>
        <CustomButton
          v-if="route.params.sectionTemplateId === 'new'"
          :loading="saveLoading"
          button-type="admin-primary"
          :disabled="sectionNotComplete"
          @click="createSectionTemplate"
        >
          Create Section
        </CustomButton>
        <CustomButton
          v-if="
            route.params.sectionTemplateId !== 'new' &&
            route.name !== 'Section Access Controls' &&
            !isReadOnly
          "
          :disabled="duplicateSectionLoading"
          button-type="admin-secondary"
          :start-icon="DocumentDuplicateIcon"
          @click="duplicateSection"
        />
        <CustomButton
          v-if="
            route.params.sectionTemplateId !== 'new' &&
            authStore.isAtLeastStaffUser &&
            route.name !== 'Section Access Controls' &&
            !isReadOnly
          "
          button-type="admin-secondary"
          :start-icon="LockOpenIcon"
          @click="router.push({ name: 'Section Access Controls' })"
        />
        <CustomButton
          v-if="
            route.params.sectionTemplateId !== 'new' &&
            route.name !== 'Section Access Controls' &&
            !isReadOnly
          "
          button-type="admin-secondary"
          :start-icon="TrashIcon"
          @click="deleteSectionModalStatus = true"
        />
      </div>
    </div>

    <div v-if="sectionLoading" class="flex w-full items-center justify-center">
      <AppLoadingSpinner class="py-20" :loading="sectionLoading" />
    </div>
    <router-view v-else />
  </div>

  <!-- Delete modal -->
  <CustomDeleteModal
    title="Delete Section"
    message="Are you sure you want to delete this section? This cannot be undone."
    :modal-status="deleteSectionModalStatus"
    :loading="deleteSectionLoading"
    @confirm="confirmDeleteSection"
    @cancel="deleteSectionModalStatus = false"
  />
</template>
