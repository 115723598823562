<script setup lang="ts">
import type { Component } from 'vue'
import { Button } from '@/components/modern/ui/button'
import { useLink, type RouteLocationRaw } from 'vue-router'

const props = defineProps<{ route: { label: string; to: RouteLocationRaw; icon: Component } }>()
const { isActive } = useLink({ to: props.route.to })
</script>

<template>
  <Button
    :variant="isActive ? 'outline' : 'ghost'"
    size="xs"
    as-child
    class="w-full items-center justify-start p-3 hover:bg-white"
  >
    <router-link :to="route.to">
      <component :is="route.icon" class="mr-1.5 size-4 flex-shrink-0" />
      <span>{{ route.label }}</span>
    </router-link>
  </Button>
</template>
