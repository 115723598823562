import type { CharacterOutput } from '../models/CharacterOutput';
import type { CreateCharacterInput } from '../models/CreateCharacterInput';
import type { CreateCharacterOutput } from '../models/CreateCharacterOutput';
import type { CreateRubricInput } from '../models/CreateRubricInput';
import type { CreateRubricOutput } from '../models/CreateRubricOutput';
import type { CreateSectionTemplateInput } from '../models/CreateSectionTemplateInput';
import type { CreateSectionTemplateOutput } from '../models/CreateSectionTemplateOutput';
import type { DeleteCharacterInput } from '../models/DeleteCharacterInput';
import type { DeleteRubricInput } from '../models/DeleteRubricInput';
import type { DeleteSectionTemplateInput } from '../models/DeleteSectionTemplateInput';
import type { ListCharactersOutput } from '../models/ListCharactersOutput';
import type { ListRubricsOutput } from '../models/ListRubricsOutput';
import type { ListSectionTemplatesOutput } from '../models/ListSectionTemplatesOutput';
import type { Rubric } from '../models/Rubric';
import type { RubricOutput } from '../models/RubricOutput';
import type { SectionTemplateOutput } from '../models/SectionTemplateOutput';
import type { SectionTemplateUpdate } from '../models/SectionTemplateUpdate';
import type { SyncCharacterToRegionsInput } from '../models/SyncCharacterToRegionsInput';
import type { SyncRubricToRegionsInput } from '../models/SyncRubricToRegionsInput';
import type { UpdateCharacterInput } from '../models/UpdateCharacterInput';
import type { UploadImageInput } from '../models/UploadImageInput';
import type { UploadImageOutput } from '../models/UploadImageOutput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';


export class ContentService {

	/**
	 * CreateCharacter
	 * Creates a new character.
	 * @param requestBody 
	 * @returns CreateCharacterOutput Character created successfully.
	 * @throws ApiError
	 */
	public static createCharacterEndpoint(
requestBody: CreateCharacterInput,
): CancelablePromise<CreateCharacterOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/content/CreateCharacter',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify content.`,
			},
		});
	}

	/**
	 * CreateRubric
	 * Creates a new rubric.
	 * @param requestBody 
	 * @returns CreateRubricOutput Rubric created successfully.
	 * @throws ApiError
	 */
	public static createRubricEndpoint(
requestBody: CreateRubricInput,
): CancelablePromise<CreateRubricOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/content/CreateRubric',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify content.`,
			},
		});
	}

	/**
	 * CreateSection
	 * Creates a new section template.
	 * @param requestBody 
	 * @returns CreateSectionTemplateOutput Section template created successfully.
	 * @throws ApiError
	 */
	public static createSectionTemplateEndpoint(
requestBody: CreateSectionTemplateInput,
): CancelablePromise<CreateSectionTemplateOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/content/CreateSection',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify content.`,
			},
		});
	}

	/**
	 * DeleteCharacter
	 * Deletes a character.
	 * @param requestBody 
	 * @returns void Character deleted successfully.
	 * @throws ApiError
	 */
	public static deleteCharacterEndpoint(
requestBody: DeleteCharacterInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v2/content/DeleteCharacter',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to delete character.`,
			},
		});
	}

	/**
	 * DeleteRubric
	 * Deletes a rubric.
	 * @param requestBody 
	 * @returns void Rubric deleted successfully.
	 * @throws ApiError
	 */
	public static deleteRubricEndpoint(
requestBody: DeleteRubricInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v2/content/DeleteRubric',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to delete rubric.`,
			},
		});
	}

	/**
	 * DeleteSection
	 * Deletes a section template.
	 * @param requestBody 
	 * @returns void Section template deleted successfully.
	 * @throws ApiError
	 */
	public static deleteSectionTemplateEndpoint(
requestBody: DeleteSectionTemplateInput,
): CancelablePromise<void> {
				return __request(OpenAPI, {
			method: 'DELETE',
			url: '/api/v2/content/DeleteSection',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to delete section templates.`,
			},
		});
	}

	/**
	 * GetCharacter
	 * Retrieve a character by ID.
	 * @param characterId The ID of the character.
	 * @returns CharacterOutput Character retrieved successfully.
	 * @throws ApiError
	 */
	public static getCharacterEndpoint(
characterId: string,
): CancelablePromise<CharacterOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/content/GetCharacter',
			query: {
				character_id: characterId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query content.`,
			},
		});
	}

	/**
	 * GetRubric
	 * Retrieve a rubric by ID.
	 * @param rubricId The ID of the rubric to retrieve.
	 * @returns RubricOutput Rubric retrieved successfully.
	 * @throws ApiError
	 */
	public static getRubricEndpoint(
rubricId: string,
): CancelablePromise<RubricOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/content/GetRubric',
			query: {
				rubric_id: rubricId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query content.`,
			},
		});
	}

	/**
	 * GetSectionTemplate
	 * Retrieve a section template by ID.
	 * @param sectionTemplateId The ID of the section template to retrieve.
	 * @returns SectionTemplateOutput Section template retrieved successfully.
	 * @throws ApiError
	 */
	public static getSectionTemplateEndpoint(
sectionTemplateId: string,
): CancelablePromise<SectionTemplateOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/content/GetSectionTemplate',
			query: {
				section_template_id: sectionTemplateId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query content.`,
			},
		});
	}

	/**
	 * ListCharacters
	 * Retrieve all characters.
	 * @param query The search query.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @param organizationId Filter characters visible to this organization only.
 * 
 * Only available to SuperAdmins.
	 * @returns ListCharactersOutput Characters retrieved successfully.
	 * @throws ApiError
	 */
	public static listCharactersEndpoint(
query: string,
itemsPerPage: number,
page: number,
organizationId?: string | null,
): CancelablePromise<ListCharactersOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/content/ListCharacters',
			query: {
				query, items_per_page: itemsPerPage, page, organization_id: organizationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query content.`,
			},
		});
	}

	/**
	 * ListRubrics
	 * Search rubrics.
	 * @param query The search query.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @param organizationId Filter rubrics visible to this organization only.
 * 
 * Only available to SuperAdmins.
	 * @returns ListRubricsOutput Rubrics retrieved successfully.
	 * @throws ApiError
	 */
	public static listRubricsEndpoint(
query: string,
itemsPerPage: number,
page: number,
organizationId?: string | null,
): CancelablePromise<ListRubricsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/content/ListRubrics',
			query: {
				query, items_per_page: itemsPerPage, page, organization_id: organizationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query content.`,
			},
		});
	}

	/**
	 * ListSections
	 * Search section templates.
	 * @param query The search query.
	 * @param itemsPerPage Number of items per page.
	 * @param page Page number. Starts from 1.
	 * @param organizationId Filter section templates visible to this organization only.
 * 
 * Only available to SuperAdmins.
	 * @returns ListSectionTemplatesOutput Section templates retrieved successfully.
	 * @throws ApiError
	 */
	public static listSectionTemplatesEndpoint(
query: string,
itemsPerPage: number,
page: number,
organizationId?: string | null,
): CancelablePromise<ListSectionTemplatesOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/content/ListSections',
			query: {
				query, items_per_page: itemsPerPage, page, organization_id: organizationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query content.`,
			},
		});
	}

	/**
	 * SyncCharacterToRegions
	 * Syncs a character to other regions.
	 * @param requestBody 
	 * @returns unknown SyncCharacterToRegions.
	 * @throws ApiError
	 */
	public static syncCharacterToRegionsEndpoint(
requestBody: SyncCharacterToRegionsInput,
): CancelablePromise<unknown> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/content/SyncCharacterToRegions',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * SyncRubricToRegions
	 * Syncs a rubric to other regions.
	 * @param requestBody 
	 * @returns unknown SyncRubricToRegions.
	 * @throws ApiError
	 */
	public static syncRubricToRegionsEndpoint(
requestBody: SyncRubricToRegionsInput,
): CancelablePromise<unknown> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/content/SyncRubricToRegions',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * UpdateCharacter
	 * Update an existing character.
	 * @param requestBody 
	 * @returns CharacterOutput Character updated successfully.
	 * @throws ApiError
	 */
	public static updateCharacterEndpoint(
requestBody: UpdateCharacterInput,
): CancelablePromise<CharacterOutput> {
				return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v2/content/UpdateCharacter',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify content.`,
			},
		});
	}

	/**
	 * UpdateRubric
	 * Update an existing rubric.
	 * @param requestBody 
	 * @returns RubricOutput Rubric updated successfully.
	 * @throws ApiError
	 */
	public static updateRubricEndpoint(
requestBody: Rubric,
): CancelablePromise<RubricOutput> {
				return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v2/content/UpdateRubric',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify content.`,
			},
		});
	}

	/**
	 * UpdateSection
	 * Update an existing section template.
	 * @param requestBody 
	 * @returns SectionTemplateOutput Section template updated successfully.
	 * @throws ApiError
	 */
	public static updateSectionTemplateEndpoint(
requestBody: SectionTemplateUpdate,
): CancelablePromise<SectionTemplateOutput> {
				return __request(OpenAPI, {
			method: 'PUT',
			url: '/api/v2/content/UpdateSection',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify content.`,
			},
		});
	}

	/**
	 * UploadImage
	 * Upload an image to S3, returning its URL.
	 * @param requestBody 
	 * @returns UploadImageOutput Image uploaded successfully.
	 * @throws ApiError
	 */
	public static uploadImageEndpoint(
requestBody: UploadImageInput,
): CancelablePromise<UploadImageOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/content/UploadImage',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to modify content.`,
			},
		});
	}

}