<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUtilsStore } from '@/stores/utils'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import { validateEmail, getWorkbookFromFile, findEmailsInWorkbook } from '@/utils'
import type { SearchOrganizationUser } from '@/open-api/generated'
import useSearchOrganizationUsers from '@/composables/api/queries/useSearchOrganizationUsers'
import useBatchAddCohortStudents from '@/composables/api/mutations/useBatchAddCohortStudents'
import { CommandState } from '@/composables/api/mutations/types'
import { Label } from '@/components/modern/ui/label'
import { Button } from '@/components/modern/ui/button'
import { SearchSelect } from '@/components/modern/ui/search-select'
import { Input } from '@/components/modern/ui/input'
import { Switch } from '@/components/modern/ui/switch'
import { Separator } from '@/components/modern/ui/separator'
import { TrashIcon, PlusIcon, ChevronLeftIcon } from '@radix-icons/vue'
import { SUPPORTED_SPREADSHEET_FORMATS } from '@/constants'

definePage({
  name: 'Modern Cohorts - Add Learners to Cohort',
  meta: {
    permissionLevel: 'InstitutionAdmin'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Add Learners to Cohort')

const route = useRoute('Modern Cohorts - Add Learners to Cohort')
const cohortId = computed(() => route.params.cohortId)

// Typecast assumes organizationId is defined
// Safe because can't get here without being logged in
const utilsStore = useUtilsStore()
const organizationId = computed(() => utilsStore.organizationId!)

const router = useRouter()
const notificationStore = useNotificationStore()

// The list of emails to be added to the cohort
const emails = ref<string[]>([])
// Keep the list of emails unique
const addEmailIfUnique = (email: string) => {
  if (!emails.value.includes(email)) {
    emails.value.push(email)
  }
}
// Whether to create new users if they're not found
const createIfNotFound = ref(false)

// Handle spreadsheet uploads
const handleFile = async (event: Event) => {
  const file = (event.target as HTMLInputElement).files?.[0]
  if (file) {
    const workbook = await getWorkbookFromFile(file)
    // Replace emails array, as workflow is intended to be "upload CSV and then refine"
    emails.value = findEmailsInWorkbook(workbook)
  }
}

// Set up SearchSelect input
const query = ref<string>('')
const { users, isLoading: searchLoading } = useSearchOrganizationUsers({
  organizationId,
  query,
  notificationStore
})
// Push to emails array, as intended workflow is "refine uploaded CSV"
const addSelectedUser = (user: SearchOrganizationUser) => addEmailIfUnique(user.email)

// Set up manual email input
const manualEmail = ref<string>('')
// Push to emails array, as intended workflow is "refine uploaded CSV"
const addManualEmail = () => {
  const email = validateEmail(manualEmail.value.trim())
  if (email) {
    addEmailIfUnique(manualEmail.value)
    manualEmail.value = ''
  } else {
    notificationStore.addWARNING('Not recognized as a valid email address')
  }
}

const { state, execute } = useBatchAddCohortStudents({ cohortId, emails, notificationStore })
const submitLoading = computed(() => state.value === CommandState.IN_PROGRESS)

const onSubmit = async () => {
  const result = await execute({ createIfNotFound: createIfNotFound.value })
  if (result) {
    emails.value = result
  } else {
    router.push({
      name: 'Modern Cohorts - Cohort Learners List',
      params: { cohortId: route.params.cohortId }
    })
  }
}
</script>

<template>
  <Button class="mb-6 w-min" variant="outline" as-child>
    <RouterLink :to="{ name: 'Modern Cohorts - Cohort Learners List', params: { cohortId } }">
      <ChevronLeftIcon class="size-4" />
      Back to Cohort Learners
    </RouterLink>
  </Button>
  <div class="flex w-3/4 max-w-xl flex-col gap-4 self-center">
    <p class="text-lg">Upload a spreadsheet of learners to be added:</p>
    <div class="mx-4 flex flex-col gap-2">
      <Label for="upload-users">Upload an Excel spreadsheet or CSV file</Label>
      <!-- @vue-expect-error Input does not specify `type` attribute. -->
      <Input
        id="upload-users"
        type="file"
        :accept="SUPPORTED_SPREADSHEET_FORMATS"
        @change="handleFile"
      />
    </div>

    <p class="text-center text-xs font-medium text-muted-foreground">or</p>

    <p class="text-lg">Select individual learners to be added:</p>
    <div class="mx-4 flex flex-col gap-2">
      <Label for="search-users">Search for a user in your organization</Label>
      <SearchSelect
        id="search-users"
        v-model:query="query"
        value-key="id"
        label-key="email"
        :data="users"
        placeholder-label="user"
        :loading="searchLoading"
        clear-on-select
        @select-element="addSelectedUser"
      />
    </div>

    <p class="text-center text-xs font-medium text-muted-foreground">or</p>

    <div class="mx-4 flex flex-col gap-2">
      <Label for="enter-email">Enter email address manually</Label>
      <div class="flex flex-row gap-2">
        <!-- @vue-expect-error Input does not specify `type` attribute. -->
        <Input id="enter-email" v-model="manualEmail" @keyup.enter="addManualEmail" />
        <Button variant="outline" size="icon" @click="addManualEmail"
          ><PlusIcon class="size-4"
        /></Button>
      </div>
    </div>

    <Separator class="my-8" label="then" />
    <!-- TODO: table/list -->
    <p class="text-lg">Review and submit the list of learners:</p>

    <p>{{ emails.length }} learners to be added</p>
    <ul class="mt-6">
      <li v-for="(email, index) in emails" :key="email" class="font-mono">
        <span class="font-bold">{{ email }}</span>
        <span>
          <Button variant="outline" size="icon" @click="emails.splice(index, 1)">
            <TrashIcon class="size-4" />
          </Button>
        </span>
      </li>
    </ul>

    <div class="flex h-9 items-center gap-3 space-y-0">
      <Switch
        id="create-if-not-found"
        :checked="createIfNotFound"
        @update:checked="createIfNotFound = $event"
      />
      <Label for="create-if-not-found">Create new accounts for new emails?</Label>
    </div>
    <p class="text-sm font-bold text-destructive">
      {{ createIfNotFound ? 'New user accounts may be created in your organization.' : '&nbsp;' }}
    </p>
    <!-- @vue-expect-error Button does not specify `disabled` attribute. -->
    <Button :disabled="submitLoading" class="mb-12 mt-4 w-min" @click="onSubmit"
      >Add Learners</Button
    >
  </div>
</template>
