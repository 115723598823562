<script setup lang="ts">
import { computed, ref } from 'vue'
import { useUtilsStore } from '@/stores/utils'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetOrganizationAssignments from '@/composables/api/queries/useGetOrganizationAssignments'
import type { OrganizationAssignment } from '@/open-api/generated'
import useDeleteAssignmentV2 from '@/composables/api/mutations/useDeleteAssignmentV2'
import { CommandState } from '@/composables/api/mutations/types'
import { DataTable } from '@/components/modern/tables'
import { useColumns } from '@/components/modern/tables/organization-activities'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Activities - All Activities List',
  meta: {
    permissionLevel: 'Educator'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('All Activities')

const utilsStore = useUtilsStore()
// Asserting organizationId will always be defined
// Safe to do this because you can't be logged in without an organizationId
const organizationId = computed(() => utilsStore.organizationId!)

const notificationStore = useNotificationStore()
const { isLoading, assignments, ...serverPagination } = useGetOrganizationAssignments({
  organizationId,
  notificationStore
})
const assignmentId = ref<string>('')
const { state, execute } = useDeleteAssignmentV2({ assignmentId, notificationStore })
const requestDeleteAssignment = ({ assignment_id }: OrganizationAssignment) => {
  assignmentId.value = assignment_id
}
const confirmDeleteAssignment = async () => {
  await execute()
  if (state.value == CommandState.SUCCESS) {
    serverPagination.refetch()
  }
}

const organizationActivities = useColumns({ requestDeleteAssignment, confirmDeleteAssignment })
</script>

<template>
  <div class="flex justify-between">
    <p class="text-2xl font-semibold">Activities</p>
    <Button size="lg" class="text-base" as-child>
      <RouterLink :to="{ name: 'Modern Activities - New Activity' }">
        <PlusIcon class="mr-3 size-5" />
        Author Activity
      </RouterLink>
    </Button>
  </div>
  <DataTable
    :data="assignments"
    :columns="organizationActivities"
    :loading="isLoading"
    :server-pagination="serverPagination"
  />
</template>
