<script setup lang="ts">
import { usePageHeadingStore } from '@/stores/pageHeading'

const pageHeadingStore = usePageHeadingStore()
</script>

<template>
  <div>
    <h1 class="p-1.5 text-center text-sm font-medium">
      {{ pageHeadingStore.pageHeading }}
    </h1>
    <slot />
  </div>
</template>
