<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import type { GetCohortStudent } from '@/open-api/generated'
import useGetCohortStudents from '@/composables/api/queries/useGetCohortStudents'
import useRemoveStudentFromCohort from '@/composables/api/mutations/useRemoveStudentFromCohort'
import { DataTable } from '@/components/modern/tables'
import { useColumns } from '@/components/modern/tables/cohort-students'
import { Tabs, TabsList, TabsTrigger } from '@/components/modern/ui/tabs'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - Cohort Learners List',
  meta: {
    permissionLevel: 'Educator'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Cohort Learners')

const route = useRoute('Modern Cohorts - Cohort Learners List')
const cohortId = computed(() => route.params.cohortId)

const notificationStore = useNotificationStore()

const { isLoading, students, refetch } = useGetCohortStudents({ cohortId, notificationStore })

const { execute } = useRemoveStudentFromCohort({ notificationStore })
const removeStudent = async (student: GetCohortStudent) => {
  await execute({ userId: student.id, cohortId: cohortId.value })
  refetch()
}

const cohortStudents = useColumns({ removeStudent })
</script>

<template>
  <p class="mb-4 text-2xl font-semibold">Cohort Learners</p>
  <div class="flex items-end justify-between">
    <Tabs default-value="learners">
      <TabsList class="grid w-full grid-cols-2">
        <TabsTrigger value="activities" as-child>
          <RouterLink
            :to="{ name: 'Modern Cohorts - Cohort Activities List', params: { cohortId } }"
          >
            Activities
          </RouterLink>
        </TabsTrigger>
        <TabsTrigger value="learners"> Learners </TabsTrigger>
      </TabsList>
    </Tabs>
    <Button size="lg" class="text-base" as-child>
      <RouterLink :to="{ name: 'Modern Cohorts - Add Learners to Cohort', params: { cohortId } }">
        <PlusIcon class="mr-3 size-5" />
        Add Learners
      </RouterLink>
    </Button>
  </div>
  <DataTable :data="students" :columns="cohortStudents" :loading="isLoading" />
</template>
