<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useUtilsStore } from '@/stores/utils'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetOrganizationCohorts from '@/composables/api/queries/useGetOrganizationCohorts'
import generateResourceId from '@/utils/generateResourceId'
import { Button } from '@/components/modern/ui/button'
import { Label } from '@/components/modern/ui/label'
import { SearchSelect } from '@/components/modern/ui/search-select'
import { ChevronLeftIcon } from '@radix-icons/vue'
import AssignToCohortForm from '@/components/modern/AssignToCohortForm'

definePage({
  name: 'Modern Activities - Assign to Cohort',
  meta: {
    permissionLevel: 'Educator'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Assign Activity')

// Get the relevant route params
const route = useRoute('Modern Activities - Assign to Cohort')
const assignmentId = computed(() => route.params.activityId)

// Assertion that organizationId is defined is safe because we are in a guarded route
const utilsStore = useUtilsStore()
const organizationId = computed(() => utilsStore.organizationId!)

const notificationStore = useNotificationStore()

// Set up SearchSelect for selecting a cohort
const cohortId = ref<string>()
const { isLoading, cohorts } = useGetOrganizationCohorts({
  organizationId,
  notificationStore
})

// Set up fresh assignmentInstanceId
const assignmentInstanceId = ref<string>(generateResourceId('assignment_instance_id'))
watch([assignmentId, cohortId], () => {
  assignmentInstanceId.value = generateResourceId('assignment_instance_id')
})
</script>

<template>
  <Button class="mb-6 w-min" variant="outline" as-child>
    <RouterLink :to="{ name: 'Modern Activities - All Activities List' }">
      <ChevronLeftIcon class="size-4" />
      Back to All Activities
    </RouterLink>
  </Button>
  <div class="flex w-3/4 max-w-xl flex-col gap-8 self-center">
    <div class="flex flex-col gap-2">
      <Label for="search-cohorts">Search for a cohort</Label>
      <SearchSelect
        id="search-cohorts"
        v-model:selected-value="cohortId"
        :data="cohorts"
        value-key="id"
        label-key="name"
        placeholder-label="cohort"
        placeholder-label-plural="cohorts"
        :loading="isLoading"
      />
    </div>

    <AssignToCohortForm
      v-if="cohortId"
      :cohort-id="cohortId"
      :assignment-id="assignmentId"
      :assignment-instance-id="assignmentInstanceId"
    />
  </div>
</template>
