<script setup lang="ts">
import {
  useFeaturesStore,
  toggleAssignmentCompletionInsightsStatisticsFeature
} from '@/stores/features'
import FeatureFlag from '@/components/FeatureFlag.vue'

definePage({
  name: 'Management Feature Flags',
  meta: {
    permissionLevel: 'SuperAdmin'
  }
})
const featuresStore = useFeaturesStore()
</script>

<template>
  <div class="flex flex-col gap-8">
    <div>
      <h2 class="mb-3 text-xl">Feature Flags</h2>
      <p>
        Disable or enable feature flags using the PostHog toolbar:
        <a href="https://eu.posthog.com/project/12445/toolbar">open the PostHog dashboard</a> and
        click "Launch" on the appropriate url to launch a new window with the PostHog toolbar.
      </p>
    </div>
    <div>
      <FeatureFlag
        :class="{ 'opacity-50': featuresStore.featuresLoading }"
        title="Assignment Completion Insights/Statistics UI changes"
        :feature="featuresStore.assignmentCompletionInsightsStatisticsFeature"
        @click="toggleAssignmentCompletionInsightsStatisticsFeature"
      />
    </div>
  </div>
</template>
