<script setup lang="ts">
import { ref, type Ref, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetMyCohorts from '@/composables/api/queries/useGetMyCohorts'
import useGetMyAssignments from '@/composables/api/queries/useGetMyAssignments'
import useGetMyLegacyCohorts from '@/composables/api/queries/useGetMyLegacyCohorts'
import useGetMyLegacyAssignments from '@/composables/api/queries/useGetMyLegacyAssignments'

import type { MyAssignmentSummary } from '@/open-api/generated'
// awaiting endpoint/composable
import type { ModernAssignmentSummary } from '@/composables/api/queries/useGetMyAssignments'
import {
  AssignmentStatusApiEnum,
  AssignmentInstanceStudentStatusApiEnum
} from '@/open-api/generated'
import createIsoTimes from '@/utils/iso-times'
import type { Table } from '@tanstack/vue-table'
import {
  DataTable,
  DataTableControls,
  DataTableGroupTitle,
  DataTableGroupEmpty
} from '@/components/modern/tables'
import { useColumns } from '@/components/modern/tables/my-activities'
import { Button } from '@/components/modern/ui/button'
import {
  QuestionMarkIcon,
  BellIcon,
  BackpackIcon,
  CircleIcon,
  RadiobuttonIcon
} from '@radix-icons/vue'
import WalkthroughModal from '@/components/modern/WalkthroughModal.vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'

definePage({
  name: 'Modern - My Activities',
  meta: {
    permissionLevel: 'Student'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('My Activities')

const authStore = useAuthStore()
const notificationStore = useNotificationStore()

// Modern
const { cohorts, isLoading: loadingCohorts } = useGetMyCohorts({ notificationStore })
const cohortIds = computed<string[]>(() => cohorts.value.map((c) => c.id))

const { assignments, isLoading: loadingAssignments } = useGetMyAssignments({
  cohortIds,
  notificationStore
})

// const isLoading = computed<boolean>(() => loadingCohorts.value || loadingAssignments.value)
// TODO(LegacySupport)
let isLoading = computed<boolean>(() => loadingCohorts.value || loadingAssignments.value)
let legacyAssignments = { value: [] } as unknown as Ref<MyAssignmentSummary[]>

if (authStore.flagLegacyEnabled) {
  const { legacyCohorts, isLoading: loadingLegacyCohorts } = useGetMyLegacyCohorts({
    notificationStore
  })
  const legacyCohortIds = computed<string[]>(() => legacyCohorts.value.map((c) => c.cohort_id))

  const { legacyAssignments: innerLegacyAssignments, isLoading: loadingLegacyAssignments } =
    useGetMyLegacyAssignments({
      cohortIds: legacyCohortIds,
      notificationStore
    })

  isLoading = computed<boolean>(
    () =>
      loadingCohorts.value ||
      loadingLegacyCohorts.value ||
      loadingAssignments.value ||
      loadingLegacyAssignments.value
  )
  legacyAssignments = innerLegacyAssignments
}

const isoTimes = createIsoTimes()

// About `ref<Table<unknown> | null>(null)`:
// Template ref to access the `table` instance, which is exposed by `defineExpose` on DataTable
// This is the cleanest way to pass multiple child tables to a single DataTableControls component
// https://vuejs.org/api/sfc-script-setup.html#defineexpose
// https://vuejs.org/guide/typescript/composition-api.html#typing-template-refs

// Pre-sort and group assignments
const openTableRef = ref<Table<unknown> | null>(null)
const openData = computed<Array<MyAssignmentSummary | ModernAssignmentSummary>>(() =>
  [
    ...assignments.value.filter(
      (a) =>
        a.status === AssignmentInstanceStudentStatusApiEnum.NOT_STARTED ||
        a.status === AssignmentInstanceStudentStatusApiEnum.IN_PROGRESS
    ),
    // TODO(LegacySupport)
    ...legacyAssignments.value.filter(
      (a) =>
        a.status === AssignmentStatusApiEnum.NOT_STARTED ||
        a.status === AssignmentStatusApiEnum.IN_PROGRESS ||
        a.status === AssignmentStatusApiEnum.OVERDUE
    )
  ]
    // remove very old assignments
    .filter((a) => a.due_date >= isoTimes.isoLongAgo)
    // sort by due date ascending
    .sort((a, b) => (a.due_date < b.due_date ? -1 : a.due_date > b.due_date ? 1 : 0))
)
const openEmpty = computed<boolean>(() => openData.value.length === 0)

const pastTableRef = ref<Table<unknown> | null>(null)
const pastData = computed<Array<MyAssignmentSummary | ModernAssignmentSummary>>(() =>
  [
    ...assignments.value.filter(
      (a) =>
        a.status === AssignmentInstanceStudentStatusApiEnum.PASS ||
        a.status === AssignmentInstanceStudentStatusApiEnum.FAIL ||
        // include very old assignments
        a.due_date < isoTimes.isoLongAgo
    ),
    // TODO(LegacySupport)
    ...legacyAssignments.value.filter(
      (a) =>
        a.status === AssignmentStatusApiEnum.COMPLETED ||
        a.status === AssignmentStatusApiEnum.COMPLETED_LATE ||
        // include very old assignments
        a.due_date < isoTimes.isoLongAgo
    )
  ] // sort by due date descending
    .sort((a, b) => (a.due_date > b.due_date ? -1 : a.due_date < b.due_date ? 1 : 0))
)
const pastEmpty = computed<boolean>(() => pastData.value.length === 0)

const myActivities = useColumns({
  isoTimes
})
</script>

<template>
  <div class="flex w-full flex-row flex-nowrap items-center justify-between">
    <WalkthroughModal>
      <Button variant="outline" size="icon">
        <QuestionMarkIcon class="size-6 text-muted-foreground" />
      </Button>
    </WalkthroughModal>
    <h1 class="my-2 text-2xl">My Activities</h1>
    <div class="flex flex-row items-center gap-2 text-muted-foreground">
      <Button variant="outline" class="text-base">
        <!-- <RouterLink :to="{ name: 'Modern - Export My Portfolio' }"> -->
        <BackpackIcon class="mr-3 size-5" />
        My Portfolio
        <!-- </RouterLink> -->
      </Button>
      <Button variant="outline" size="icon">
        <BellIcon class="size-6 text-muted-foreground" />
      </Button>
    </div>
  </div>
  <template v-if="isLoading">
    <AppLoadingSpinner :loading="isLoading" center-screen />
  </template>
  <template v-else>
    <DataTableControls :tables="[openTableRef, pastTableRef]" />

    <DataTableGroupTitle :empty="openEmpty">
      <CircleIcon class="size-6" />
      <p class="text-lg">Open Activities</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="openEmpty"> No open activities. </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="openTableRef"
      :data="openData"
      :columns="myActivities"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />

    <DataTableGroupTitle :empty="pastEmpty">
      <RadiobuttonIcon class="size-6" />
      <p class="text-lg">Past Activities</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="pastEmpty"> No past activities. </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="pastTableRef"
      :data="pastData"
      :columns="myActivities"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />
  </template>
</template>
