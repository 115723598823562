<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetCohortAssignmentInsights from '@/composables/api/queries/useGetCohortAssignmentInsights'
import { type CohortAssignmentInsight, AssignmentStatusV2ApiEnum } from '@/open-api/generated'
import useVoidAssignmentInstance from '@/composables/api/mutations/useVoidAssignmentInstance'
import { CommandState } from '@/composables/api/mutations/types'
import type { Table } from '@tanstack/vue-table'
import {
  DataTable,
  DataTableControls,
  DataTableGroupTitle,
  DataTableGroupEmpty
} from '@/components/modern/tables'
import { useColumns } from '@/components/modern/tables/cohort-activities'
import { Tabs, TabsList, TabsTrigger } from '@/components/modern/ui/tabs'
import { Button } from '@/components/modern/ui/button'
import {
  PlusIcon,
  PieChartIcon,
  CircleIcon,
  CounterClockwiseClockIcon,
  ValueNoneIcon
} from '@radix-icons/vue'
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue'

definePage({
  name: 'Modern Cohorts - Cohort Activities List',
  meta: {
    permissionLevel: 'Educator'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Cohort Activities')

const route = useRoute('Modern Cohorts - Cohort Activities List')
const cohortId = computed(() => route.params.cohortId)

const notificationStore = useNotificationStore()

const { isLoading, assignments, refetch } = useGetCohortAssignmentInsights({
  cohortId,
  notificationStore
})

const assignmentInstanceId = ref<string>('')
const { execute, state } = useVoidAssignmentInstance({ assignmentInstanceId, notificationStore })
const requestVoidInstance = ({ assignment_instance_id }: CohortAssignmentInsight) => {
  assignmentInstanceId.value = assignment_instance_id
}
const confirmVoidInstance = async () => {
  await execute()
  if (state.value == CommandState.SUCCESS) {
    refetch()
  }
}

const cohortActivities = useColumns({ cohortId, requestVoidInstance, confirmVoidInstance })

// About `ref<Table<unknown> | null>(null)`:
// Template ref to access the `table` instance, which is exposed by `defineExpose` on DataTable
// This is the cleanest way to pass multiple child tables to a single DataTableControls component
// https://vuejs.org/api/sfc-script-setup.html#defineexpose
// https://vuejs.org/guide/typescript/composition-api.html#typing-template-refs

const openTableRef = ref<Table<unknown> | null>(null)
const openData = computed(() =>
  assignments.value.filter((a) => a.status === AssignmentStatusV2ApiEnum.OPEN)
)
const openEmpty = computed<boolean>(() => openData.value.length === 0)

const upcomingTableRef = ref<Table<unknown> | null>(null)
const upcomingData = computed(() =>
  assignments.value.filter((a) => a.status === AssignmentStatusV2ApiEnum.UPCOMING)
)
const upcomingEmpty = computed<boolean>(() => upcomingData.value.length === 0)

const pastTableRef = ref<Table<unknown> | null>(null)
const pastData = computed(() =>
  assignments.value.filter((a) => a.status === AssignmentStatusV2ApiEnum.PAST)
)
const pastEmpty = computed<boolean>(() => pastData.value.length === 0)

const voidedTableRef = ref<Table<unknown> | null>(null)
const voidedData = computed(() =>
  assignments.value.filter((a) => a.status === AssignmentStatusV2ApiEnum.VOIDED)
)
const voidedEmpty = computed<boolean>(() => voidedData.value.length === 0)
</script>

<template>
  <p class="mb-4 text-2xl font-semibold">Cohort Activities</p>
  <div class="flex items-end justify-between">
    <Tabs default-value="activities">
      <TabsList class="grid w-full grid-cols-2">
        <TabsTrigger value="activities">Activities</TabsTrigger>
        <TabsTrigger value="learners" as-child>
          <RouterLink :to="{ name: 'Modern Cohorts - Cohort Learners List', params: { cohortId } }">
            Learners
          </RouterLink>
        </TabsTrigger>
      </TabsList>
    </Tabs>
    <Button size="lg" class="text-base" as-child>
      <RouterLink :to="{ name: 'Modern Cohorts - Assign Activity', params: { cohortId } }">
        <PlusIcon class="mr-3 size-5" />
        Assign Activity
      </RouterLink>
    </Button>
  </div>
  <template v-if="isLoading">
    <AppLoadingSpinner :loading="isLoading" center-screen />
  </template>
  <template v-else>
    <DataTableControls :tables="[openTableRef, upcomingTableRef, pastTableRef, voidedTableRef]" />

    <DataTableGroupTitle :empty="openEmpty">
      <PieChartIcon class="size-6" />
      <p class="text-lg">Open Activities</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="openEmpty"> No open activities. </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="openTableRef"
      :data="openData"
      :columns="cohortActivities"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />

    <DataTableGroupTitle :empty="upcomingEmpty">
      <CircleIcon class="size-6" />
      <p class="text-lg">Upcoming Activities</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="upcomingEmpty"> No upcoming activities. </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="upcomingTableRef"
      :data="upcomingData"
      :columns="cohortActivities"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />

    <DataTableGroupTitle :empty="pastEmpty">
      <CounterClockwiseClockIcon class="size-6" />
      <p class="text-lg">Past Activities</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="pastEmpty"> No past activities. </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="pastTableRef"
      :data="pastData"
      :columns="cohortActivities"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />

    <DataTableGroupTitle :empty="voidedEmpty">
      <ValueNoneIcon class="size-6" />
      <p class="text-lg">Voided Activities</p>
    </DataTableGroupTitle>
    <DataTableGroupEmpty v-if="voidedEmpty"> No voided activities. </DataTableGroupEmpty>
    <DataTable
      v-else
      ref="voidedTableRef"
      :data="voidedData"
      :columns="cohortActivities"
      :loading="isLoading"
      class="!mt-0 mb-12"
      disable-controls
      disable-pagination
    />
  </template>
</template>
