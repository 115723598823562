import { defineStore } from 'pinia'
import type { WhoAmIOutput } from '@/open-api/generated'
import { Role, type RoleString } from '@/types/auth'
import { ref, computed } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import Api from '@/open-api'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const notificationStore = useNotificationStore()
    const whoAmI = ref<WhoAmIOutput | undefined>()
    const geoError = ref<boolean>(false)
    const syntheticRole = ref<Role | undefined>()

    const trueRole = computed<Role>(() =>
      whoAmI.value?.user_role ? Role[whoAmI.value.user_role] : Role.ANY
    )
    const isTrueSuperAdmin = computed<boolean>(() => trueRole.value === Role.SuperAdmin)
    const role = computed<Role>(() => {
      if (isTrueSuperAdmin.value) {
        return syntheticRole.value || trueRole.value
      }
      return trueRole.value
    })
    const isSuperAdminUser = computed<boolean>(() => role.value === Role.SuperAdmin)
    const isAtLeastStaffUser = computed<boolean>(() => role.value >= Role.Staff)
    const isAtLeastInstitutionAdminUser = computed<boolean>(
      () => role.value >= Role.InstitutionAdmin
    )
    const isAtLeastEducatorUser = computed<boolean>(() => role.value >= Role.Educator)
    const isAtLeastStudentUser = computed<boolean>(() => role.value >= Role.Student)
    const organizationId = computed<string | undefined>(() => whoAmI.value?.organization_id)
    const flagAuthoringEnabled = computed<boolean>(
      () => whoAmI.value?.flag_authoring_enabled || false
    )
    // TODO: Implement this on the backend
    const flagLegacyEnabled = computed<boolean>(
      () => whoAmI.value?.flag_legacy_features_enabled || false
    )
    const flagModernEnabled = computed<boolean>(
      () => whoAmI.value?.flag_modern_features_enabled || false
    )
    const orgColor = computed<string>(
      () => (whoAmI.value?.flag_powered_by_enabled && whoAmI.value?.accent_color) || '#FC894B'
    )
    const orgLogo = computed<string>(
      () => (whoAmI.value?.flag_powered_by_enabled && whoAmI.value?.logo_url) || ''
    )
    const notificationIncompleteAssignments = computed<boolean>(
      () => whoAmI.value?.notification_incomplete_assignments ?? true
    )

    const setWhoAmI = (newWhoAmI: WhoAmIOutput | undefined) => (whoAmI.value = newWhoAmI)
    // Typecast assumes updateWhoAmI only called when whoAmI not undefined.
    // TODO: Unclear if reasonable assumption
    const updateWhoAmI = (whoAmIUpdate: Partial<WhoAmIOutput>) =>
      (whoAmI.value = { ...(whoAmI.value as WhoAmIOutput), ...whoAmIUpdate })
    const setGeoError = (newGeoError: boolean) => (geoError.value = newGeoError)
    const clearStore = () => (whoAmI.value = undefined)
    const setSyntheticRole = (newRole: RoleString) => (syntheticRole.value = Role[newRole])

    const setNotificationIncompleteAssignments = (allowNotifications: boolean) => {
      const onSetNotificationSuccess = () => {
        notificationStore.addSUCCESS('Updated notifications')
        updateWhoAmI({ notification_incomplete_assignments: allowNotifications })
      }
      const onSetNotificationFailure = () => {
        notificationStore.addDANGER('Unable to update notifications')
      }
      if (whoAmI.value) {
        Api.Management.updateUserEndpoint({
          user_id: whoAmI.value.user_id,
          user_role: whoAmI.value.user_role,
          notification_incomplete_assignments: allowNotifications
        }).then(onSetNotificationSuccess, onSetNotificationFailure)
      } else {
        onSetNotificationFailure()
      }
    }

    // Normally prefer returning readonly proxies of refs in stores.
    // Was causing whoAmI not to be settable. Likely persistence bug.
    // Not clear that this store even needs to be persisted.
    // TODO: Investigate
    return {
      whoAmI,
      geoError,
      syntheticRole,
      trueRole,
      role,
      isTrueSuperAdmin,
      isSuperAdminUser,
      isAtLeastStaffUser,
      isAtLeastInstitutionAdminUser,
      isAtLeastEducatorUser,
      isAtLeastStudentUser,
      organizationId,
      flagAuthoringEnabled,
      flagLegacyEnabled,
      flagModernEnabled,
      orgColor,
      orgLogo,
      notificationIncompleteAssignments,
      setWhoAmI,
      updateWhoAmI,
      setGeoError,
      clearStore,
      setSyntheticRole,
      setNotificationIncompleteAssignments
    }
  },
  { persist: true }
)
