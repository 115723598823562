<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import generateResourceUuid from '@/utils/generateResourceId'
import useCreateAssignmentV2, { schema } from '@/composables/api/mutations/useCreateAssignmentV2'
import type { z } from 'zod'
import { CommandState } from '@/composables/api/mutations/types'
import { AutoForm, type Config } from '@/components/modern/ui/auto-form'
import { ACTIVITY_FORM_DESCRIPTION_FIELD_DESCRIPTION } from '@/constants'
import { Button } from '@/components/modern/ui/button'

definePage({
  name: 'Modern Activities - New Activity',
  meta: {
    permissionLevel: 'Educator'
  }
})
const router = useRouter()
const notificationStore = useNotificationStore()

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('New Activity')

const assignmentId = ref<string>(generateResourceUuid('assignment_id'))

const { state, execute } = useCreateAssignmentV2({
  assignmentId,
  notificationStore
})

const fieldConfig: Config<z.infer<typeof schema>> = {
  description: {
    component: 'textarea',
    description: ACTIVITY_FORM_DESCRIPTION_FIELD_DESCRIPTION
  }
}

const submit = async (form: any) => {
  await execute({ form })
  if (state.value === CommandState.FAILURE) {
    assignmentId.value = generateResourceUuid('assignment_id')
  }
  if (state.value === CommandState.SUCCESS) {
    router.push({
      name: 'Modern Activities - Edit Activity',
      params: { activityId: assignmentId.value }
    })
  }
}
</script>

<template>
  <AutoForm
    :schema="schema"
    class="mt-8 w-3/4 max-w-lg self-center"
    :field-config="fieldConfig"
    @submit="submit"
  >
    <Button>Create</Button>
  </AutoForm>
</template>
