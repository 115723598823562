<script setup lang="ts">
import { computed } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import TheStatusNotification from '@/components/AppNotification.vue'
import type { Notification } from '@/types/notification'

// ==================================================
// Init
// ==================================================
const notificationStore = useNotificationStore()
const animationDuration = 200
const statusNotifications = computed((): Notification[] => notificationStore.notifications)
</script>

<template>
  <Teleport to="body">
    <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 z-[100] flex items-end px-4 py-6 sm:p-6"
    >
      <div class="mt-10 flex w-full flex-col items-center space-y-4 sm:items-end md:mt-0">
        <transition-group
          :enter-active-class="'transform ease-out duration-' + animationDuration + '-group'"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          :leave-active-class="'transition ease-in duration-' + animationDuration"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <TheStatusNotification
            v-for="(notification, key) in statusNotifications"
            :key="key"
            :notification="notification"
          />
        </transition-group>
      </div>
    </div>
  </Teleport>
</template>
