<script setup lang="ts">
import AppBrainIcon from '@/components/AppBrainIcon.vue'
import { useRouter } from 'vue-router'

definePage({
  redirect: '/my-assignments',
  meta: {
    permissionLevel: 'ANY'
  }
})

const router = useRouter()
router.push('/my-assignments')
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="flex flex-1" />
    <div class="flex flex-1 flex-col">
      <AppBrainIcon class="mx-auto max-h-64 max-w-64" />
      <h1 class="flex flex-1 items-center justify-center text-2xl font-medium text-sc-grey-800">
        Welcome to SimConverse.
      </h1>
      <p class="flex flex-1 items-center justify-center text-sc-grey-600">
        Taking you to your assignments.
      </p>
      <router-link
        class="flex flex-1 items-center justify-center text-blue-400"
        to="/my-assignments"
      >
        Click here if you are not redirected.
      </router-link>
    </div>
    <div class="flex flex-1" />
  </div>
</template>
