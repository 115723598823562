<script setup lang="ts">
import { watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetAssignment from '@/composables/api/queries/useGetAssignment'
import useUpdateAssignmentV2, { schema } from '@/composables/api/mutations/useUpdateAssignmentV2'
import useUpdateAssignmentTasks, {
  mapTaskFormToValues
} from '@/composables/api/mutations/useUpdateAssignmentTasks'
import useTasks from '@/composables/useTasks'
import { CommandState } from '@/composables/api/mutations/types'
import type { z } from 'zod'
import { useForm, type GenericObject } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import {
  ACTIVITY_FORM_DESCRIPTION_FIELD_DESCRIPTION,
  ACTIVITY_FORM_INSTRUCTIONS_FIELD_DESCRIPTION
} from '@/constants'
import { NewTask, AnyTaskForm } from '@/components/modern/task-forms'
import { AutoForm, type Config } from '@/components/modern/ui/auto-form'
import { Button } from '@/components/modern/ui/button'

definePage({
  name: 'Modern Activities - Edit Activity',
  meta: {
    permissionLevel: 'Educator'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Edit Activity')

const route = useRoute('Modern Activities - Edit Activity')
const assignmentId = computed<string>(() => route.params.activityId)

const notificationStore = useNotificationStore()

const { assignment, refetch } = useGetAssignment({ assignmentId, notificationStore })
const { tasks, addTask, ...handlers } = useTasks(assignment)

const updateAssignment = useUpdateAssignmentV2({ assignmentId, notificationStore })
const updateTasks = useUpdateAssignmentTasks({ assignmentId, notificationStore })

const form = useForm({
  validationSchema: toTypedSchema(schema)
})

const fieldConfig: Config<z.infer<typeof schema>> = {
  description: {
    component: 'textarea',
    description: ACTIVITY_FORM_DESCRIPTION_FIELD_DESCRIPTION
  },
  instructions: {
    component: 'textarea',
    description: ACTIVITY_FORM_INSTRUCTIONS_FIELD_DESCRIPTION
  }
}

// Fill in the form with the server values from GetAssignment
watch(assignment, () => {
  form.resetForm({
    values: {
      name: assignment.value?.name,
      description: assignment.value?.description,
      instructions: assignment.value?.instructions
    }
  })
})

const onSubmit = form.handleSubmit(async (values: z.infer<typeof schema>) => {
  const assignmentValues = { instructions: '', ...values }
  const tasksValues = { tasks: tasks.value.map(mapTaskFormToValues) }

  await Promise.all([updateAssignment.execute(assignmentValues), updateTasks.execute(tasksValues)])
  // If all mutations succeeded, refetch the assignment
  if (
    updateAssignment.state.value === CommandState.SUCCESS &&
    updateTasks.state.value === CommandState.SUCCESS
  ) {
    refetch()
  }
  // Then reset the mutation so the user can submit more changes
  updateAssignment.reset()
  updateTasks.reset()
}) as (event: GenericObject) => any
// The above cast is necessary to satisfy vee-validate's odd typing

const hasTasks = computed<boolean>(() => tasks.value.length > 0)
</script>

<template>
  <div class="flex w-5/6 max-w-4xl flex-col items-center gap-8 self-center">
    <AutoForm :schema="schema" :form="form" :field-config="fieldConfig" @submit="onSubmit">
      <!-- @vue-expect-error Button does not specify `type` prop -->
      <Button type="submit">Save Changes</Button>
    </AutoForm>
    <template v-if="hasTasks">
      <AnyTaskForm
        v-for="(task, index) in tasks"
        :key="task.assignmentTaskId"
        :task="task"
        :index="index"
        :is-last="index === tasks.length - 1"
        v-on="handlers"
      />
    </template>
    <template v-else>
      <p class="text-muted-foreground">
        No tasks have been added yet. Add a task from the options below.
      </p>
    </template>
    <NewTask @add-task="addTask" />
    <div v-if="hasTasks" class="mb-12 self-start">
      <!-- @vue-expect-error Button does not specify `click` as an emit -->
      <Button @click="onSubmit">Save Changes</Button>
    </div>
  </div>
</template>
