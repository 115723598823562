<script setup lang="ts">
import { ref } from 'vue'
import generateResourceId from '@/utils/generateResourceId'
import { useRouter } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useCreateCohortV2, { schema } from '@/composables/api/mutations/useCreateCohortV2'
import { CommandState } from '@/composables/api/mutations/types'
import { AutoForm } from '@/components/modern/ui/auto-form'
import { Button } from '@/components/modern/ui/button'
import {
  COHORT_FORM_NAME_FIELD_DESCRIPTION,
  COHORT_FORM_ISDEFAULT_FIELD_LABEL,
  COHORT_FORM_ISDEFAULT_FIELD_DESCRIPTION
} from '@/constants'

definePage({
  name: 'Modern Cohorts - New Cohort',
  meta: {
    permissionLevel: 'Educator'
  }
})

const router = useRouter()
const notificationStore = useNotificationStore()

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('New Cohort')

const cohortId = ref<string>(generateResourceId('cohort_id'))

const { state, execute } = useCreateCohortV2({ cohortId, notificationStore })
const onCreate = async (form: any) => {
  await execute({ form })
  if (state.value === CommandState.FAILURE) {
    cohortId.value = generateResourceId('cohort_id')
  }
  if (state.value === CommandState.SUCCESS) {
    router.push({
      name: 'Modern Cohorts - Cohort Learners List',
      params: { cohortId: cohortId.value }
    })
  }
}
</script>

<template>
  <AutoForm
    :schema="schema"
    class="mt-8 w-3/4 max-w-lg self-center"
    :field-config="{
      name: {
        description: COHORT_FORM_NAME_FIELD_DESCRIPTION
      },
      isDefault: {
        label: COHORT_FORM_ISDEFAULT_FIELD_LABEL,
        description: COHORT_FORM_ISDEFAULT_FIELD_DESCRIPTION
      }
    }"
    @submit="onCreate"
  >
    <Button>Create</Button>
  </AutoForm>
</template>
