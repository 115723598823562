<script setup lang="ts">
import type { CharacterOutput, UpdateCharacterInput } from '@/open-api/generated'
import { computed, ref } from 'vue'
import CustomInput from '@/components/utils/CustomInput.vue'
import { useContentStore } from '@/stores/content'
import { NotificationStatus } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { useAuthStore } from '@/stores/auth'
import CustomButton from '@/components/utils/CustomButton.vue'
import Api from '@/open-api'
import { useRoute, onBeforeRouteLeave } from 'vue-router'

definePage({
  name: 'Character Prompts',
  meta: {
    permissionLevel: 'Educator',
    requiresAuthoring: true
  }
})

// ==================================================
// Init
// ==================================================
const route = useRoute('Character Prompts')
const notificationStore = useNotificationStore()
const contentStore = useContentStore()
const authStore = useAuthStore()
const editingPrompt = ref(contentStore?.editingCharacter?.prompt)
const isReadOnly = computed(() => {
  return (
    route.params.characterId !== 'new' &&
    !authStore.isAtLeastStaffUser &&
    !(
      authStore.organizationId &&
      authStore.organizationId ===
        (contentStore.editingCharacter as CharacterOutput)?.owning_organization_id
    )
  )
})

const prompt = computed({
  get: () =>
    route.params.characterId === 'new'
      ? contentStore?.editingCharacter?.prompt
      : editingPrompt.value,
  set: (val) => {
    if (route.params.characterId === 'new') {
      contentStore.updateEditingCharacter({ prompt: val })
    } else {
      editingPrompt.value = val
    }
  }
})

onBeforeRouteLeave((to, from, next) => {
  if (
    route.params.characterId !== 'new' &&
    !(!editingPrompt.value || editingPrompt.value === contentStore?.editingCharacter?.prompt)
  ) {
    const answer = window.confirm(
      'Warning: You have unsaved changes. \nNavigating away from this page will delete any unsaved changes.'
    )
    if (answer) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

// ==================================================
// Save Prompt
// ==================================================
const saveLoading = ref(false)
const saveCharacterPrompt = () => {
  const { editingCharacter } = contentStore

  if (!editingCharacter) {
    return
  }

  saveLoading.value = true
  const updateCharacter = contentStore.editingCharacter as UpdateCharacterInput | CharacterOutput

  const update = {
    ...updateCharacter,
    ...{ prompt: editingPrompt.value }
  } as UpdateCharacterInput

  update.allowlist = authStore.isAtLeastStaffUser
    ? updateCharacter.allowlist?.map((listItem) => {
        if (typeof listItem === 'string') {
          return listItem
        }
        return listItem?.organization_id
      })
    : null

  Api.Content.updateCharacterEndpoint(update as UpdateCharacterInput)
    .then((res) => {
      saveLoading.value = false
      contentStore.setEditingCharacter(res)
      notificationStore.addNotification({
        subtitle: 'Character Prompt successfully saved',
        status: NotificationStatus.SUCCESS
      })
    })
    .catch((err: any) => {
      saveLoading.value = false

      notificationStore.addNotification({
        subtitle: err?.body?.message,
        status: NotificationStatus.DANGER
      })
    })
}
</script>

<template>
  <div class="flex-1 px-5">
    <CustomInput
      v-model="prompt"
      :read-only="isReadOnly"
      class="!mb-0 h-full w-full [&>textarea]:h-full"
      input-type="textarea"
      label="Prompt"
    />
  </div>
  <div class="mb-5 flex h-fit flex-none justify-end">
    <CustomButton
      v-if="route.params.characterId !== 'new' && !isReadOnly"
      button-type="admin-primary"
      :loading="saveLoading"
      class="mx-5"
      :disabled="!editingPrompt?.trim() || editingPrompt === contentStore?.editingCharacter?.prompt"
      @click="saveCharacterPrompt"
    >
      Save Prompt
    </CustomButton>
  </div>
</template>
