import { defineStore } from 'pinia'

export const useUtilsStore = defineStore('utils', {
  state: () => ({
    windowWidth: 0,
    organizationId: undefined as string | undefined,
    historyUserId: undefined as string | undefined,
    myAssignmentId: undefined as string | undefined,
    hasViewedWalkthrough: false as boolean
  }),
  getters: {
    isMobile(): boolean {
      return this.windowWidth <= 1024
    }
  },
  actions: {
    setWindowWidth(width: number) {
      this.windowWidth = width
    },
    setHasViewedWalkthrough(hasViewed: boolean) {
      this.hasViewedWalkthrough = hasViewed
    },
    setOrganizationId(organizationId: string | undefined) {
      this.organizationId = organizationId
    },
    setHistoryUserId(user: string | undefined) {
      this.historyUserId = user
    },
    setMyAssignmentId(assignmentId: string | undefined) {
      this.myAssignmentId = assignmentId
    }
  },
  persist: true
})
