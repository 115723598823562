import type { AddEducatorToCohortInput } from '../models/AddEducatorToCohortInput';
import type { AddStudentToCohortInput } from '../models/AddStudentToCohortInput';
import type { BatchAddCohortStudentsInput } from '../models/BatchAddCohortStudentsInput';
import type { BatchAddCohortStudentsOutput } from '../models/BatchAddCohortStudentsOutput';
import type { CreateCohortInputV2 } from '../models/CreateCohortInputV2';
import type { GetCohortEducatorsOutput } from '../models/GetCohortEducatorsOutput';
import type { GetCohortOutputV2 } from '../models/GetCohortOutputV2';
import type { GetCohortStudentsOutput } from '../models/GetCohortStudentsOutput';
import type { GetMyCohortsOutput } from '../models/GetMyCohortsOutput';
import type { GetOrganizationCohortsOutput } from '../models/GetOrganizationCohortsOutput';
import type { RemoveEducatorFromCohortInput } from '../models/RemoveEducatorFromCohortInput';
import type { RemoveStudentFromCohortInputV2 } from '../models/RemoveStudentFromCohortInputV2';
import type { UpdateCohortInputV2 } from '../models/UpdateCohortInputV2';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';


export class CohortService {

	/**
	 * AddEducatorToCohort
	 * Adds an educator to a cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static addEducatorToCohortEndpoint(
requestBody: AddEducatorToCohortInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/AddEducatorToCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * AddStudentToCohort
	 * Adds a student to a cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static addStudentToCohortEndpoint(
requestBody: AddStudentToCohortInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/AddStudentToCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * BatchAddCohortStudents
	 * Adds a batch of student emails to a cohort.
	 * @param requestBody 
	 * @returns BatchAddCohortStudentsOutput Success.
	 * @throws ApiError
	 */
	public static batchAddCohortStudentsEndpoint(
requestBody: BatchAddCohortStudentsInput,
): CancelablePromise<BatchAddCohortStudentsOutput> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/BatchAddCohortStudents',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * CreateCohort
	 * Creates a new cohort.
	 * @param requestBody 
	 * @returns any Create a new cohort.
	 * @throws ApiError
	 */
	public static createCohortEndpointV2(
requestBody: CreateCohortInputV2,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/CreateCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetCohort
	 * Retrieve a cohort by ID.
	 * @param cohortId The ID of the cohort to retrieve.
	 * @returns GetCohortOutputV2 Cohort retrieved successfully.
	 * @throws ApiError
	 */
	public static getCohortEndpointV2(
cohortId: string,
): CancelablePromise<GetCohortOutputV2> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetCohort',
			query: {
				cohort_id: cohortId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not allowed to query this cohort.`,
			},
		});
	}

	/**
	 * GetCohortEducators
	 * @param cohortId The ID of the cohort to get educators for.
	 * @returns GetCohortEducatorsOutput Success.
	 * @throws ApiError
	 */
	public static getCohortEducatorsEndpoint(
cohortId: string,
): CancelablePromise<GetCohortEducatorsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetCohortEducators',
			query: {
				cohort_id: cohortId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetCohortStudents
	 * @param cohortId The ID of the cohort to get students for.
	 * @returns GetCohortStudentsOutput Success.
	 * @throws ApiError
	 */
	public static getCohortStudentsEndpoint(
cohortId: string,
): CancelablePromise<GetCohortStudentsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetCohortStudents',
			query: {
				cohort_id: cohortId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetMyCohorts
	 * @returns GetMyCohortsOutput Success.
	 * @throws ApiError
	 */
	public static getMyCohortsEndpointV2(): CancelablePromise<GetMyCohortsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetMyCohorts',
			errors: {
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * GetOrganizationCohorts
	 * @param organizationId The ID of the organization to get cohorts for.
	 * @returns GetOrganizationCohortsOutput Success.
	 * @throws ApiError
	 */
	public static getOrganizationCohortsEndpoint(
organizationId: string,
): CancelablePromise<GetOrganizationCohortsOutput> {
				return __request(OpenAPI, {
			method: 'GET',
			url: '/api/v2/cohort/GetOrganizationCohorts',
			query: {
				organization_id: organizationId
			},
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * RemoveEducatorFromCohort
	 * Remove an educator from a cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static removeEducatorFromCohortEndpoint(
requestBody: RemoveEducatorFromCohortInput,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/RemoveEducatorFromCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * RemoveStudentFromCohort
	 * Remove a student from a cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static removeStudentFromCohortEndpointV2(
requestBody: RemoveStudentFromCohortInputV2,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/RemoveStudentFromCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

	/**
	 * UpdateCohort
	 * Updates an existing cohort.
	 * @param requestBody 
	 * @returns any Success.
	 * @throws ApiError
	 */
	public static updateCohortEndpointV2(
requestBody: UpdateCohortInputV2,
): CancelablePromise<any> {
				return __request(OpenAPI, {
			method: 'POST',
			url: '/api/v2/cohort/UpdateCohort',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `Bad input.`,
				401: `Logged out.`,
				403: `Not authorized.`,
			},
		});
	}

}