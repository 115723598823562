<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useSearchOrganizations from '@/composables/api/queries/useSearchOrganizations'
import useGetAssignmentAccess from '@/composables/api/queries/useGetAssignmentAccess'
import useUpdateAssignmentAccess from '@/composables/api/mutations/useUpdateAssignmentAccess'
import { CommandState } from '@/composables/api/mutations/types'
import type { OrganizationIdAndName } from '@/open-api/generated'
import { SearchSelect } from '@/components/modern/ui/search-select'
import { Button } from '@/components/modern/ui/button'
import { TrashIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Activities - Activity Access List',
  meta: {
    permissionLevel: 'InstitutionAdmin'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('Activity Access List')

const route = useRoute('Modern Activities - Activity Access List')
const assignmentId = computed<string>(() => route.params.activityId)

const notificationStore = useNotificationStore()

// The server's current access list for this assignment
const { accessList, refetch } = useGetAssignmentAccess({ assignmentId, notificationStore })
// The list of organizations to be submitted as the new access list
const updatedAccessList = ref<OrganizationIdAndName[]>(accessList.value ?? [])
// Reset the access list when we get new data from the server
watch(accessList, () => (updatedAccessList.value = accessList.value ?? []))

const addToAccessList = (org: OrganizationIdAndName) => {
  if (updatedAccessList.value.some((o) => o.organization_id === org.organization_id)) {
    // Don't add duplicates
    return
  }
  updatedAccessList.value.push(org)
}
const removeFromAccessList = (index: number) => updatedAccessList.value.splice(index, 1)

const { state, execute, reset } = useUpdateAssignmentAccess({ assignmentId, notificationStore })

// Set up the SearchSelect box
const query = ref<string>('')
const { organizations, isLoading } = useSearchOrganizations({
  query,
  notificationStore
})

const onSubmit = async () => {
  await execute(updatedAccessList.value.map((org) => org.organization_id))
  if (state.value === CommandState.SUCCESS) {
    // On a successful mutation, refetch the server data
    refetch()
  }
  // Reset the mutation to allow user to make further changes
  reset()
}

// TODO: sync global content / sync to other regions?
</script>

<template>
  <div class="mt-8 flex w-2/3 max-w-2xl flex-col gap-6 self-center">
    <SearchSelect
      v-model:query="query"
      :data="organizations"
      :loading="isLoading"
      value-key="organization_id"
      label-key="name"
      placeholder-label="organization"
      @select-element="addToAccessList"
    />
    <!-- TODO: table -->
    <ul>
      <li v-for="(org, index) in updatedAccessList" :key="org.organization_id">
        {{ org.name }}
        <Button variant="outline" size="icon" @click="removeFromAccessList(index)">
          <TrashIcon class="size-6" />
        </Button>
      </li>
    </ul>
    <Button class="w-min" @click="onSubmit">Save changes</Button>
  </div>
</template>
