<script setup lang="ts">
import { computed } from 'vue'
import { useUtilsStore } from '@/stores/utils'
import { usePageHeadingStore } from '@/stores/pageHeading'
import { useNotificationStore } from '@/stores/notifications'
import useGetOrganizationCohorts from '@/composables/api/queries/useGetOrganizationCohorts'
import { DataTable } from '@/components/modern/tables'
import { useColumns } from '@/components/modern/tables/organization-cohorts'
import { Button } from '@/components/modern/ui/button'
import { PlusIcon } from '@radix-icons/vue'

definePage({
  name: 'Modern Cohorts - All Cohorts List',
  meta: {
    permissionLevel: 'Educator'
  }
})

const pageHeadingStore = usePageHeadingStore()
pageHeadingStore.setPageHeading('All Cohorts')

const utilsStore = useUtilsStore()
// Asserting organizationId will always be defined
// Safe to do this because you can't be logged in without an organizationId
const organizationId = computed(() => utilsStore.organizationId!)

const notificationStore = useNotificationStore()
const { isLoading, cohorts } = useGetOrganizationCohorts({ organizationId, notificationStore })

const organizationCohorts = useColumns()
</script>

<template>
  <div class="flex justify-between">
    <p class="text-2xl font-semibold">Cohorts</p>
    <Button size="lg" class="text-base" as-child>
      <RouterLink :to="{ name: 'Modern Cohorts - New Cohort' }">
        <PlusIcon class="mr-3 size-5" />
        Create Cohort
      </RouterLink>
    </Button>
  </div>
  <DataTable :data="cohorts" :columns="organizationCohorts" :loading="isLoading" />
</template>
